import React from 'react'
import merge from 'lodash/merge'
import styled from 'styled-components'

import L from 'leaflet'
import { Map, Marker, TileLayer } from 'react-leaflet'
import { leafletLocale } from 'sputnik-ui'

L.drawLocal = merge(L.drawLocal, leafletLocale)

const myIcon = L.icon({
  iconUrl: require('../../../assets/place-24.svg'),
  iconSize: [38, 95],
  iconAnchor: [22, 94],
  popupAnchor: [-3, -76],
})

const StyledMap = styled(Map)`
  width: 100%;
  height: 100%;
  z-index: 0;
`

const _Map = ({ data }) =>
  data ? (
    <StyledMap
      center={{ lat: data.lat || 0, lon: data.lon || 0 }}
      zoom={17}
      zoomControl={false}
      scrollWheelZoom={false}
      touchZoom={false}
      dragging={false}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker
        position={{ lat: data.lat || 0, lon: data.lon || 0 }}
        icon={myIcon}
      />
    </StyledMap>
  ) : null

export default _Map
