//@flow

export default (match: Object, selected: Object, devices: Object) => {
  // const { country, region, area, locality, street, house, entry } = selected

  const {
    params: { country, region, area, locality, street, entry, flat, house },
  } = match

  let location = []
  let path = ``

  const setLoc = (title: string, str: string) => {
    const to = `${path}${str}`
    if (title) {
      location.push({ title, to })
    }
    path += str
  }

  if (flat) {
    const str = `/f/${flat}`
    setLoc(selected.flat && `Квартира ${selected.flat.number}`, str)
  } else if (entry) {
    const str = `/e/${entry}`
    const entryAddress = ''
    setLoc(entryAddress, str)
  } else if (house) {
    const str = `/h/${house}`
    setLoc(selected.house && selected.house.full_name, str)
  } else if (street) {
    const str = `/s/${street}`
    setLoc(selected.street && selected.street.full_name, str)
  } else if (locality) {
    const str = `/l/${locality}`
    setLoc(selected.locality && selected.locality.name, str)
  } else if (area) {
    const str = `/a/${area}`
    setLoc(selected.area && selected.area.name, str)
  } else if (region) {
    const str = `/r/${region}`
    setLoc(selected.region && selected.region.name, str)
  } else if (country) {
    const str = `/c/${country}`
    setLoc(selected.country && selected.country.name, str)
  }

  return location
}
