//@flow
import * as types from './types'

export const syncInstallationPointRequest = (): Object => ({
  type: types.SYNC_INSTALLATION_POINT_REQUEST,
})
export const syncInstallationPointSuccess = (msg: string): Object => ({
  type: types.SYNC_INSTALLATION_POINT_SUCCESS,
  payload: { msg },
})
export const syncInstallationPointError = (error: string): Object => ({
  type: types.SYNC_INSTALLATION_POINT_ERROR,
  payload: { error },
})

export const closeCollectingKeysRequest = () => ({
  type: types.CLOSE_COLLECTING_KEYS_REQUEST,
})
export const closeCollectingKeysSuccess = (msg: string) => ({
  type: types.CLOSE_COLLECTING_KEYS_SUCCESS,
  payload: {
    msg,
  },
})
export const closeCollectingKeysError = (error: string) => ({
  type: types.CLOSE_COLLECTING_KEYS_ERROR,
  payload: {
    error,
  },
})

export const openCollectingKeysRequest = () => ({
  type: types.OPEN_COLLECTING_KEYS_REQUEST,
})
export const openCollectingKeysSuccess = (msg: string) => ({
  type: types.OPEN_COLLECTING_KEYS_SUCCESS,
  payload: {
    msg,
  },
})
export const openCollectingKeysError = (error: string) => ({
  type: types.OPEN_COLLECTING_KEYS_ERROR,
  payload: {
    error,
  },
})

export const prolongCollectingKeysRequest = () => ({
  type: types.PROLONG_COLLECTING_KEYS_REQUEST,
})
export const prolongCollectingKeysSuccess = (msg: string) => ({
  type: types.PROLONG_COLLECTING_KEYS_SUCCESS,
  payload: {
    msg,
  },
})
export const prolongCollectingKeysError = (error: string) => ({
  type: types.PROLONG_COLLECTING_KEYS_ERROR,
  payload: {
    error,
  },
})

export const searchInstallationPointRequest = () => ({
  type: types.SEARCH_INSTALLATION_POINT_REQUEST,
})
export const searchInstallationPointSuccess = () => ({
  type: types.SEARCH_INSTALLATION_POINT_SUCCESS,
})
export const searchInstallationPointError = (error: string) => ({
  type: types.SEARCH_INSTALLATION_POINT_ERROR,
  payload: {
    error,
  },
})

export const generateQrRequest = () => ({
  type: types.GENERATE_QR_REQUEST,
})
export const generateQrSuccess = () => ({
  type: types.GENERATE_QR_SUCCESS,
})
export const generateQrError = (error: string) => ({
  type: types.GENERATE_QR_ERROR,
  payload: {
    error,
  },
})

export const invalidateQrRequest = () => ({
  type: types.INVALIDATE_QR_REQUEST,
})
export const invalidateQrSuccess = (msg: string) => ({
  type: types.INVALIDATE_QR_SUCCESS,
  payload: {
    msg,
  },
})
export const invalidateQrError = (error: string) => ({
  type: types.INVALIDATE_QR_ERROR,
  payload: {
    error,
  },
})
