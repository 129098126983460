//@flow
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from 'sputnik-ui'
import { connect } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Zoom from '@material-ui/core/Zoom'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import { searchInstallationPoint } from 'store/operations'

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

type Props = {
  match: Object,
  dispatch: Function,
  Header: React.ComponentType<{}>,
  history: Object,
  handleClose: Function,
  open: boolean,
  error: string,
}

type State = {
  searchQuery: string,
  error: ?string,
}

class SearchAddressPage extends React.Component<Props, State> {
  state = {
    searchQuery: '',
    error: null,
  }

  handleChange = (name) => (e) => {
    this.setState({
      [name]: e.target.value,
      error: null,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { searchQuery } = this.state
    if (searchQuery) {
      this.props
        .dispatch(searchInstallationPoint(searchQuery))
        .then(({ addressUuid, addressType }) => {
          const type = addressType === 'gate' ? 'h' : addressType.slice(0, 1)
          this.props.history.push(`/${type}/${addressUuid}`)
        })
        .catch((error) => this.setState({ error }))
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        TransitionComponent={Transition}
        onClose={this.props.handleClose}
        PaperProps={{ style: styles.content }}
      >
        <DialogTitle style={{ paddingBottom: 0 }}>Поиск адреса</DialogTitle>
        <DialogContent>
          <form onSubmit={this.handleSubmit}>
            <TextField
              value={this.state.searchQuery}
              fullWidth
              label="Номер устройства"
              onChange={this.handleChange('searchQuery')}
              margin="dense"
              autoFocus
            />

            {!!this.state.error && (
              <Typography
                align="center"
                color="error"
                style={{ marginTop: '1rem' }}
              >
                {this.state.error}
              </Typography>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.handleClose}
            secondary
            rounded
            tabIndex={-1}
          >
            отмена
          </Button>
          <Button
            primary
            rounded
            onClick={this.handleSubmit}
            disabled={!this.state.searchQuery}
          >
            искать
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const styles = {
  content: { overflow: 'visible', width: 418 },
  submitBtn: { marginLeft: 'auto', marginTop: 16 },
  input: {},
  divider: {
    backgroundColor: 'black',
    width: 30,
    height: 2,
    marginRight: '0.625rem',
    marginLeft: '0.625rem',
  },
}

const mapStateToProps = ({ devices }) => ({
  error: devices.error,
})

export default connect(mapStateToProps)(withRouter(SearchAddressPage))
