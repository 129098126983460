import React from 'react'
import styled, { css } from 'styled-components'
import { IconButton } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'

const rotated = css`
  transform: rotate(180deg);
`

const Button = styled(IconButton)`
  && {
    z-index: 2;
    position: absolute;
    bottom: -24px;
    left: calc(50% - 24px);
    right: 0;
    color: ${p => p.theme.palette.primary['500']};
    background: ${p => p.theme.palette.primary.contrastText};
    box-shadow: ${p => p.theme.shadows.main};
    border-bottom: 1px solid #e6ecf1;
    transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1);

    & svg {
      ${p => p.isExpanded && rotated};
    }

    &:hover {
      color: ${p => p.theme.palette.primary['500']};
      background: ${p => p.theme.palette.primary.contrastText};
      transform: scale(1.02);
    }
  }
`

const FoldButton = ({ isExpanded, ...props }) => {
  return (
    <Button disableRipple isExpanded={isExpanded} {...props}>
      <KeyboardArrowDown color="inherit" />
    </Button>
  )
}
export default FoldButton
