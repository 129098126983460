export const GET_KEYS_REQUEST = 'keys/GET_KEYS_REQUEST'
export const GET_KEYS_SUCCESS = 'keys/GET_KEYS_SUCCESS'
export const GET_KEYS_ERROR = 'keys/GET_KEYS_ERROR'

export const REMOVE_KEY_REQUEST = 'keys/REMOVE_KEY_REQUEST'
export const REMOVE_KEY_SUCCESS = 'keys/REMOVE_KEY_SUCCESS'
export const REMOVE_KEY_ERROR = 'keys/REMOVE_KEY_ERROR'

export const ADD_KEY_REQUEST = 'keys/ADD_KEY_REQUEST'
export const ADD_KEY_SUCCESS = 'keys/ADD_KEY_SUCCESS'
export const ADD_KEY_ERROR = 'keys/ADD_KEY_ERROR'

export const DELETE_ALL_KEYS_REQUEST: string = 'devices/DELETE_ALL_KEYS_REQUEST'
export const DELETE_ALL_KEYS_SUCCESS: string = 'devices/DELETE_ALL_KEYS_SUCCESS'
export const DELETE_ALL_KEYS_ERROR: string = 'devices/DELETE_ALL_KEYS_ERROR'

export const SYNC_KEYS_REQUEST: string = 'devices/SYNC_KEYS_REQUEST'
export const SYNC_KEYS_SUCCESS: string = 'devices/SYNC_KEYS_SUCCESS'
export const SYNC_KEYS_ERROR: string = 'devices/SYNC_KEYS_ERROR'
