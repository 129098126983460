import * as types from './types'

export const searchRequest = () => ({
  type: types.SEARCH_REQUEST,
})
export const searchSuccess = () => ({
  type: types.SEARCH_SUCCESS,
})
export const searchFailure = error => ({
  type: types.SEARCH_FAILURE,
  payload: { error },
})

export const unlinkByPhoneRequest = () => ({
  type: types.UNLINK_BY_PHONE_REQUEST,
})
export const unlinkByPhoneSuccess = msg => ({
  type: types.UNLINK_BY_PHONE_SUCCESS,
  payload: {
    msg,
  },
})
export const unlinkByPhoneFailure = error => ({
  type: types.UNLINK_BY_PHONE_FAILURE,
  payload: { error },
})
