import React from 'react'
import { Button } from 'sputnik-ui'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Zoom from '@material-ui/core/Zoom'
import Range from 'rc-slider/lib/Range'
import TextField from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'

import { changeFlatRange } from 'store/operations'
import { FormControlLabel } from '@material-ui/core'

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

const MAX = 10000
const MIN = 1

class AttachNewDeviceModal extends React.Component {
  state = {
    flatRange: [1, 5000],
    didSetInitialRange: false,
    custom_numbering: false,
  }

  static getDerivedStateFromProps(props, state) {
    if (props?.entry) {
      const { entry } = props
      const { first_flat, last_flat } = entry
      if (entry && !state.didSetInitialRange && first_flat && last_flat) {
        return {
          flatRange: [entry.first_flat, entry.last_flat],
          didSetInitialRange: true,
        }
      }
    }

    return state
  }

  componentDidMount() {}

  handleChange = (flatRange) => this.setState({ flatRange })

  onFirstFlatChange = ({ target: { value } }) =>
    this.setState({
      flatRange: [value, this.state.flatRange[1]],
    })

  onLastFlatChange = ({ target: { value } }) =>
    this.setState({
      flatRange: [this.state.flatRange[0], value],
    })

  updateFlatRange = () => {
    const {
      match: {
        params: { entry },
      },
    } = this.props
    const { flatRange, custom_numbering } = this.state
    this.props.dispatch(
      changeFlatRange({
        entry,
        firstFlat: flatRange[0],
        lastFlat: flatRange[1],
        custom_numbering,
      })
    )
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.props.handleClose}
        PaperProps={{ style: { overflow: 'visible', minWidth: 500 } }}
      >
        <DialogTitle>Диапазон квартир</DialogTitle>
        <DialogContent style={{ overflow: 'visible' }}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.custom_numbering}
                onChange={(e) =>
                  this.setState({ custom_numbering: e.target.checked })
                }
              />
            }
            label="Отключить валидацию диапазона"
          />
          <Range
            min={MIN}
            step={1}
            max={MAX}
            value={this.state.flatRange}
            onChange={this.handleChange}
            pushable={1}
            trackStyle={{
              background: 'yellow',
            }}
            activeDotStyle={{ boxShadow: 'none' }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1rem',
              alignItems: 'center',
            }}
          >
            <TextField
              // label="Первая квартира"
              value={this.state.flatRange[0]}
              type="number"
              onChange={this.onFirstFlatChange}
              inputProps={{
                style: {
                  textAlign: 'center',
                },
                min: MIN,
                step: 1,
                max: MAX - 1,
              }}
            />
            <div style={styles.divider} />
            <TextField
              // label="Последняя квартира"
              value={this.state.flatRange[1]}
              type="number"
              onChange={this.onLastFlatChange}
              inputProps={{
                style: {
                  textAlign: 'center',
                },
                min: MIN + 1,
                step: '1',
                max: MAX,
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} rounded>
            отмена
          </Button>
          <Button primary rounded onClick={this.updateFlatRange}>
            обновить
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const styles = {
  divider: {
    backgroundColor: 'black',
    width: 30,
    height: 2,
    marginRight: '0.625rem',
    marginLeft: '0.625rem',
  },
}

const mapStateToProps = ({ devices: { camera }, resources: { current } }) => ({
  camera,
  entry: current,
})

export default connect(mapStateToProps)(withRouter(AttachNewDeviceModal))
