import React from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import EntryQRCode from '../../molecules/EntryQRCode'
import { Container } from 'sputnik-ui'

const Wrapper = styled.div`
  padding: 1rem 1.625rem 0.625rem;
  background: ${p => p.theme.palette.grayscale[2]};
  display: flex;
  align-items: flex-end;
  height: auto;
`

const AddressBlock = ({ address, showQr, entry, ...props }) => {
  return (
    <Wrapper>
      <Container>
        {showQr && (
          <EntryQRCode
            entryUuid={entry && entry.uuid}
            style={{ marginRight: '0.625rem' }}
          />
        )}

        <Typography style={{ display: 'inline' }} color="inherit">
          {address}
        </Typography>
      </Container>
    </Wrapper>
  )
}

const mapStateToProps = ({ devices, selected }) => ({
  address:
    (devices.collection &&
      devices.collection[0] &&
      devices.collection[0].address) ||
    (devices.cameras && (devices.cameras[0] && devices.cameras[0].address)),
  entry: selected.entry,
})

export default connect(mapStateToProps)(AddressBlock)
