import React from 'react'
import { Button } from 'sputnik-ui'
import { useDispatch } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Zoom from '@material-ui/core/Zoom'

import SyncIcon from '@material-ui/icons/Sync'

import { Typography } from '@material-ui/core'

import { removeFlat } from 'store/resources/operations'

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

function RemoveFlatModal({ open, uuid, handleClose }) {
  const dispatch = useDispatch()

  const handleDelete = () => {
    dispatch(removeFlat(uuid))
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      PaperProps={{ style: styles.dialog }}
    >
      <DialogTitle style={{ paddingBottom: 5, marginBottom: 20 }}>
        Удалить квартиру
      </DialogTitle>
      <DialogContent
        style={{
          overflow: 'visible',
        }}
      >
        <Typography color="error" align="center">
          Уверены что хотите удалить квартиру {uuid} ?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} secondary rounded size="small">
          закрыть
        </Button>
        <Button
          primary
          variant="contained"
          rounded
          onClick={handleDelete}
          size="small"
        >
          <SyncIcon style={styles.icon} /> Удалить
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  icon: {
    marginRight: 8,
    width: '1rem',
    height: '1rem',
  },
  dialog: {
    overflow: 'visible',
    width: '80%',
    maxWidth: 800,
  },
  responsiveContainer: {
    height: 0,
    paddingTop: '56.25%',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 5,
    boxShadow: '0px 0px 30px rgba(0,0,0,0.1)',
  },
  responsiveElement: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgb(250, 251, 250)',
  },
}

export default RemoveFlatModal
