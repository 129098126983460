import React from 'react'
import { Button, Select, Loader } from 'sputnik-ui'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Zoom from '@material-ui/core/Zoom'

import {
  getUnlinkedCameras,
  linkDevice,
  getIntercomsByAddress,
} from 'store/operations'

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

class AttachNewCameraModal extends React.Component {
  state = { selected: null }

  componentDidMount() {
    this.props.dispatch(getUnlinkedCameras())
  }

  handleSelect = selected => this.setState({ selected })

  attachCamera = () => {
    const {
      dispatch,
      match: {
        params: { entry, house },
      },
    } = this.props

    dispatch(
      linkDevice({
        house_uuid: house,
        entry_uuid: entry,
        device_uuid: this.state.selected.value,
        device_type: 'camera',
      }),
    ).then(() => {
      if (entry) {
        dispatch(getIntercomsByAddress(entry))
      }
      this.props.dispatch(getUnlinkedCameras())
      this.setState({ selected: null })
      this.props.handleClose()
    })
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        TransitionComponent={Transition}
        onClose={this.props.handleClose}
        PaperProps={{ style: { overflow: 'visible' } }}
      >
        <DialogTitle>Привязать новую камеру</DialogTitle>
        <DialogContent style={{ overflow: 'visible' }}>
          {this.props.unlinkedCameras ? (
            <Select
              options={this.props.unlinkedCameras.map(x => ({
                label: x.serial_number,
                value: x.uuid,
              }))}
              handleSelect={this.handleSelect}
              selected={this.state.selected}
              placeholder="Выберите камеру"
              isMulti={false}
              autoFocus
            />
          ) : (
            <Loader
              msg="Загружаем список непривязанных камер..."
              error={this.props.error}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} rounded>
            отмена
          </Button>
          <Button onClick={this.attachCamera} primary rounded>
            подтвердить
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = ({
  devices: { unlinkedCameras, error },
  selected: { entry },
}) => ({
  unlinkedCameras,
  error,
  entry,
})

export default connect(mapStateToProps)(withRouter(AttachNewCameraModal))
