//@flow
import * as types from './types'

export const getIntercomsByAddressRequest = (): Object => ({
  type: types.GET_INTERCOMS_BY_ADDRESS_REQUEST,
})
export const getIntercomsByAddressSuccess = (intercoms: Object[]): Object => ({
  type: types.GET_INTERCOMS_BY_ADDRESS_SUCCESS,
  payload: { intercoms },
})
export const getIntercomsByAddressError = (error: string): Object => ({
  type: types.GET_INTERCOMS_BY_ADDRESS_ERROR,
  payload: error,
})

export const getCamerasByAddressRequest = (): Object => ({
  type: types.GET_CAMERAS_BY_ADDRESS_REQUEST,
})
export const getCamerasByAddressSuccess = (cameras: Object[]): Object => ({
  type: types.GET_CAMERAS_BY_ADDRESS_SUCCESS,
  payload: { cameras },
})
export const getCamerasByAddressError = (error: string): Object => ({
  type: types.GET_CAMERAS_BY_ADDRESS_ERROR,
  payload: error,
})

export const getUnlinkedIntercomsRequest = (): Object => ({
  type: types.GET_UNLINKED_INTERCOMS_REQUEST,
})
export const getUnlinkedIntercomsSuccess = (intercoms: Object[]): Object => ({
  type: types.GET_UNLINKED_INTERCOMS_SUCCESS,
  payload: { intercoms },
})
export const getUnlinkedIntercomsError = (error: string): Object => ({
  type: types.GET_UNLINKED_INTERCOMS_ERROR,
  payload: error,
})

export const getUnlinkedCamerasRequest = (): Object => ({
  type: types.GET_UNLINKED_CAMERAS_REQUEST,
})
export const getUnlinkedCamerasSuccess = (cameras: Object[]): Object => ({
  type: types.GET_UNLINKED_CAMERAS_SUCCESS,
  payload: { cameras },
})
export const getUnlinkedCamerasError = (error: string): Object => ({
  type: types.GET_UNLINKED_CAMERAS_ERROR,
  payload: error,
})

export const linkDeviceRequest = (): Object => ({
  type: types.LINK_DEVICE_REQUEST,
})
export const linkDeviceSuccess = (msg: string): Object => ({
  type: types.LINK_DEVICE_SUCCESS,
  payload: msg,
})
export const linkDeviceError = (error: string): Object => ({
  type: types.LINK_DEVICE_ERROR,
  payload: error,
})

export const unlinkIntercomRequest = (): Object => ({
  type: types.UNLINK_INTERCOM_REQUEST,
})
export const unlinkIntercomSuccess = (msg: string): Object => ({
  type: types.UNLINK_INTERCOM_SUCCESS,
  payload: msg,
})
export const unlinkIntercomError = (error: string): Object => ({
  type: types.UNLINK_INTERCOM_ERROR,
  payload: error,
})

export const getCameraRequest = (): Object => ({
  type: types.GET_CAMERA_REQUEST,
})
export const getCameraSuccess = (camera: string): Object => ({
  type: types.GET_CAMERA_SUCCESS,
  payload: camera,
})
export const getCameraError = (error: string): Object => ({
  type: types.GET_CAMERA_ERROR,
  payload: error,
})

export const syncIntercomRequest = (): Object => ({
  type: types.SYNC_INTERCOM_REQUEST,
})
export const syncIntercomSuccess = (msg: string): Object => ({
  type: types.SYNC_INTERCOM_SUCCESS,
  payload: msg,
})
export const syncIntercomError = (error: string): Object => ({
  type: types.SYNC_INTERCOM_ERROR,
  payload: error,
})

export const syncCameraRequest = () => ({
  type: types.SYNC_CAMERA_REQUEST,
})
export const syncCameraSuccess = (msg: string) => ({
  type: types.SYNC_CAMERA_SUCCESS,
  payload: {
    msg,
  },
})
export const syncCameraError = (error: string) => ({
  type: types.SYNC_CAMERA_ERROR,
  payload: {
    error,
  },
})

export const syncIntercomCameraRequest = () => ({
  type: types.SYNC_INTERCOM_CAMERA_REQUEST,
})
export const syncIntercomCameraSuccess = (msg: string) => ({
  type: types.SYNC_INTERCOM_CAMERA_SUCCESS,
  payload: {
    msg,
  },
})
export const syncIntercomCameraError = (error: string) => ({
  type: types.SYNC_INTERCOM_CAMERA_ERROR,
  payload: {
    error,
  },
})

export const unlinkCameraRequest = () => ({
  type: types.UNLINK_CAMERA_REQUEST,
})
export const unlinkCameraSuccess = (msg: string) => ({
  type: types.UNLINK_CAMERA_SUCCESS,
  payload: {
    msg,
  },
})
export const unlinkCameraError = (error: string) => ({
  type: types.UNLINK_CAMERA_ERROR,
  payload: {
    error,
  },
})
