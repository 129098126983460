import React from 'react'
import { connect } from 'react-redux'
import { Loader } from 'sputnik-ui'
import { withRouter } from 'react-router-dom'
import { Card, withSearch } from 'components'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import DomainIcon from '@material-ui/icons/Domain'

import { selectFlat } from 'store/actions'
import { getFlats } from 'store/operations'

import { ReactComponent as Subscription } from 'assets/subscription.svg'

const searchFlats = (props, query) => {
  const { dispatch } = props

  const parent_uuid = props.selected.entry.uuid
  const q = query || null

  dispatch(
    getFlats({
      parent_uuid,
      q,
    })
  )
}

const Flats = ({
  flats,
  firstFlat,
  lastFlat,
  err,
  dispatch,
  history,
  subscriptions,
  ...props
}) => {
  if (!flats) {
    return <Loader msg="Загружаем список квартир..." err={err} fluid />
  }

  if (flats) {
    flats.forEach((el) => {
      //mutate flats add subscriptions to data
      const subscription = subscriptions.filter(
        (elem) => el.uuid === elem.uuid
      )[0]
      if (el.uuid === subscription.uuid) {
        el = { ...el, ...subscription }
      }
      return el
    })
  }

  return (
    <Card
      title={`Квартиры (${firstFlat || '~'} - ${lastFlat || '~'})`}
      titleIcon={<DomainIcon />}
      {...props}
    >
      {flats.map((_) => (
        <ListItem
          key={_.uuid}
          button
          onClick={() => {
            dispatch(selectFlat(_))
            history.push(`/f/${_.uuid}`)
          }}
        >
          <ListItemIcon>
            <DomainIcon />
          </ListItemIcon>
          <ListItemText primary={`№${_.number}`} />
          <ListItemSecondaryAction
            style={{
              paddingRight: '1rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {_.subscribe_count ? (
              <Subscription
                style={{
                  marginRight: '.8rem',
                  width: 17.15,
                }}
              />
            ) : null}

            <ArrowForwardIcon />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </Card>
  )
}

const mapStateToProps = ({ resources }) => ({
  flats: resources.current && resources.current.flats,
  firstFlat: resources.current && resources.current.first_flat,
  lastFlat: resources.current && resources.current.last_flat,
  err: resources.err,
  subscriptions: resources.current && resources.current?.flats,
})

let toExp = withSearch(searchFlats)(Flats)
toExp = withRouter(toExp)

export default connect(mapStateToProps)(toExp)
