const config = {
  baseUri:
    process.env.APP_BASE_URL ||
    'https://gis.stage.sputnik.systems/api/v1/public',
  redirectUri:
    process.env.APP_REDIRECT_URL ||
    'https://gis.stage.sputnik.systems',
  ssoUri:
    process.env.APP_SSO_URL ||
    'https://sso.sputnik.systems',
  brand: 'спутник',
  app: 'гис',
}

export default config
