import * as types from './types'

export const selectCountry = country => ({
  type: types.SELECT_COUNTRY,
  payload: country,
})

export const selectRegion = region => ({
  type: types.SELECT_REGION,
  payload: region,
})

export const selectLocality = locality => ({
  type: types.SELECT_LOCALITY,
  payload: locality,
})

export const selectArea = area => ({
  type: types.SELECT_AREA,
  payload: area,
})

export const selectStreet = street => ({
  type: types.SELECT_STREET,
  payload: street,
})

export const selectHouse = house => ({
  type: types.SELECT_HOUSE,
  payload: house,
})

export const selectEntry = entry => ({
  type: types.SELECT_ENTRY,
  payload: entry,
})

export const selectFlat = flat => ({
  type: types.SELECT_FLAT,
  payload: flat,
})
