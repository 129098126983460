import React, { useCallback, useState } from 'react'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { DeleteForever } from '@material-ui/icons'
import Videocam from '@material-ui/icons/Videocam'
import ConfirmActionModal from 'components/organisms/ConfirmActionModal'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { unlinkCamera, getCamerasByAddress } from 'store/devices/operations'

const Camera = ({ serial_number, uuid, onClick, children }) => {
  const [confirmActionModal, setConfirmActionModal] = useState(false)
  const dispatch = useDispatch()
  const unlinking = useSelector(({ devices }) => devices.unlinkingCamera)

  const { entry, house } = useParams()

  return (
    <ListItem disableRipple dense onClick={onClick} button>
      <ListItemIcon>
        <Videocam />
      </ListItemIcon>
      <ListItemText primary={serial_number} />
      <ListItemSecondaryAction>
        <IconButton
          disableRipple
          onClick={(e) => {
            e.stopPropagation()
            setConfirmActionModal(true)
          }}
        >
          <DeleteForever color="error" />
        </IconButton>
      </ListItemSecondaryAction>
      <ConfirmActionModal
        open={confirmActionModal}
        handleClose={(e) => {
          e.stopPropagation()
          setConfirmActionModal(false)
        }}
        handleAction={(e) => {
          e.stopPropagation()
          dispatch(unlinkCamera(uuid)).then(() => {
            dispatch(getCamerasByAddress(entry || house))
            setConfirmActionModal(false)
          })
        }}
        action="отвязать камеру"
        loading={unlinking}
      />
    </ListItem>
  )
}
export default Camera
