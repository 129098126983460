//@flow
import * as React from 'react'
import {
  EntryPageTemplate,
  Entries,
  Cameras,
  AttachNewIntercomModal,
  AttachNewCameraModal,
  IntercomDevice,
  CameraModal,
  UnlinkDeviceModal,
} from 'components'
import { Container, Wrapper } from 'sputnik-ui'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'

import IconButton from '@material-ui/core/IconButton'
import { AttachFile, Videocam } from '@material-ui/icons'

import Tooltip from '@material-ui/core/Tooltip'

import {
  getCamerasByAddress,
  getIntercomsByAddress,
  fetchHouse,
} from 'store/operations'
import { clearResources } from 'store/actions'

const Subheader = styled(Wrapper)`
  background: ${(p) => p.theme.palette.other.peach};
  padding: 0.325rem 0;
  border-radius: 0;
  justify-content: center;
`

function HousePage({
  match: {
    params: { house },
  },
  dispatch,
  camera,
  cameras,
  intercoms,
  currentAddress,
  ...props
}) {
  React.useEffect(() => {
    if (house) {
      dispatch(fetchHouse(house))
      dispatch(getCamerasByAddress(house))
      dispatch(getIntercomsByAddress(house))
    }
    return () => dispatch(clearResources())
  }, [dispatch, house])

  const [attachNewCameraModal, setAttachNewCameraModal] = React.useState(false)
  const [attachNewIntercomModal, setAttachNewIntercomModal] = React.useState(
    false,
  )
  const [cameraModal, setCameraModal] = React.useState(false)

  const [unlinkDeviceModal, setUnlinkDeviceModal] = React.useState(false)
  const [currentDevice, setCurrentDevice] = React.useState('')

  return (
    <EntryPageTemplate
      subheader={
        <Subheader shadow={false} border={false}>
          <Container style={{ height: 'auto' }}>
            <Tooltip title="Прикрепить новую камеру" placement="bottom">
              <IconButton
                color="inherit"
                onClick={() => setAttachNewCameraModal(true)}
                disableRipple
              >
                <Videocam />
              </IconButton>
            </Tooltip>

            <Tooltip title="Прикрепить новую панель" placement="bottom">
              <IconButton
                color="inherit"
                onClick={() => setAttachNewIntercomModal(true)}
                disableRipple
              >
                <AttachFile />
              </IconButton>
            </Tooltip>
          </Container>
        </Subheader>
      }
    >
      <Entries {...props}> </Entries>
      <div>
        {intercoms &&
          intercoms.map((d) => (
            <IntercomDevice
              {...props}
              device={d}
              key={d.uuid}
              handleUnlink={(device) => () => {
                setCurrentDevice(device)
                setUnlinkDeviceModal(true)
              }}
              openCameraModal={(device) => () => {
                setCurrentDevice(device)
                setCameraModal(true)
              }}
            />
          ))}
        {cameras && <Cameras />}
      </div>

      <UnlinkDeviceModal
        open={unlinkDeviceModal}
        handleClose={() => setUnlinkDeviceModal(false)}
        device={currentDevice}
      />
      <CameraModal
        open={cameraModal}
        handleClose={() => setCameraModal(false)}
        device={currentDevice}
        camera={camera}
      />
      <AttachNewCameraModal
        open={attachNewCameraModal}
        handleClose={() => setAttachNewCameraModal(false)}
      />
      <AttachNewIntercomModal
        open={attachNewIntercomModal}
        handleClose={() => setAttachNewIntercomModal(false)}
      />
    </EntryPageTemplate>
  )
}

const mapStateToProps = ({ devices, resources, keys }) => ({
  camera: devices.camera,
  intercoms: devices.collection,
  cameras: devices.cameras,
  currentAddress: resources.current && resources.current.full_address,
})

export default connect(mapStateToProps)(withRouter(HousePage))
