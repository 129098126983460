//@flow
import * as React from 'react'
import {
  EntryPageTemplate,
  Flats,
  IntercomDevice,
  UnlinkDeviceModal,
  CameraModal,
  Keys,
  InstallationPointActions,
} from 'components'
import { Loader } from 'sputnik-ui'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import {
  getIntercomsByAddress,
  syncIntercom,
  fetchEntry,
  getKeys,
  getCamerasByAddress,
} from 'store/operations'
import { clearResources } from 'store/actions'
import Cameras from '../../molecules/Cameras'

type Props = {
  match: Object,
  dispatch: Function,
  classes: Object,
  selected: Object,
  intercoms: Object[],
  cameras: Object[],
  error: string,
  Header: React.ComponentType<{}>,
  msg: string,
}

function EntryPage({
  match: {
    params: { entry },
  },
  dispatch,
  intercoms,
  cameras,
  camera,
  error,
  ...props
}: Props) {
  React.useEffect(() => {
    if (entry) {
      dispatch(fetchEntry(entry))
      dispatch(getIntercomsByAddress(entry))
      // dispatch(getFlats({ parent_uuid: entry }))
      dispatch(getKeys(entry))
      dispatch(getCamerasByAddress(entry))
    }
    return () => dispatch(clearResources())
  }, [dispatch, entry])

  const [cameraModal, setCameraModal] = React.useState(false)

  const [unlinkDeviceModal, setUnlinkDeviceModal] = React.useState(false)
  const [currentDevice, setCurrentDevice] = React.useState('')

  if (!intercoms)
    return (
      <Loader
        fullPage
        msg="Загружаем список привязанных устройств"
        error={error}
      />
    )

  return (
    <EntryPageTemplate subheader={<InstallationPointActions />}>
      <Flats />
      <div>
        {intercoms &&
          intercoms.map((d) => (
            <IntercomDevice
              device={d}
              key={d.uuid}
              handleUnlink={(device) => () => {
                setCurrentDevice(device)
                setUnlinkDeviceModal(true)
              }}
              openCameraModal={(device) => () => {
                setCurrentDevice(device)
                setCameraModal(true)
              }}
            />
          ))}
        <Cameras />
        <Keys />
      </div>

      <UnlinkDeviceModal
        open={unlinkDeviceModal}
        handleClose={() => setUnlinkDeviceModal(false)}
        device={currentDevice}
      />
      <CameraModal
        open={cameraModal}
        handleClose={() => setCameraModal(false)}
        device={currentDevice}
        camera={camera}
      />
    </EntryPageTemplate>
  )
}

const mapStateToProps = ({ devices, resources, keys }) => ({
  camera: devices.camera,
  intercoms: devices.collection,
  error: devices.error || resources.error || keys.error,
  msg: devices.msg || resources.msg || keys.msg,
  keys: keys.list,
})

export default connect(mapStateToProps)(withRouter(EntryPage))
