import React from 'react'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'

import NfcIcon from '@material-ui/icons/Nfc'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import RateReview from '@material-ui/icons/RateReview'

const Key = ({ onClick, children }) => {
  function copyKey() {
    const el = document.createElement('textarea')
    el.value = children
    document.body && document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body && document.body.removeChild(el)
  }
  return (
    <ListItem>
      <ListItemIcon>
        <NfcIcon />
      </ListItemIcon>
      <ListItemText primary={children} />
      <ListItemSecondaryAction>
        <IconButton disableRipple onClick={copyKey}>
          <RateReview />
        </IconButton>
        <IconButton disableRipple onClick={onClick}>
          <DeleteForeverIcon color="error" />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
export default Key
