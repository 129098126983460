import React, { useState } from 'react'
import { Loader, Mascotte, Wrapper } from 'sputnik-ui'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'

import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import Tooltip from '@material-ui/core/Tooltip'

import VideocamIcon from '@material-ui/icons/Videocam'
import CameraModal from '../../organisms/CameraModal'
import Camera from '../../atoms/Camera'
import FoldButton from '../../atoms/FoldButton'

const StyledHeader = styled(CardHeader)`
  && {
    background-color: ${(p) => p.theme.palette.primary[500]};
    color: ${(p) => p.theme.palette.primary.contrastText};
    position: sticky;
    z-index: 2;
    top: 0;
    height: 80px;
  }
`

const StyledContent = styled(CardContent)`
  && {
    transition: max-height 150ms ease-in;
    background-color: ${(p) => p.theme.palette.background.primary};

    max-height: 0rem;
    padding: 0 24px;
    overflow-y: scroll;
    padding: 0;
    &:last-child {
      padding-bottom: 0;
    }
    &::-webkit-scrollbar: {
      width: 3px;
      backgroundcolor: transparent;
    }
    &::-webkit-scrollbar-track: {
      backgroundcolor: transparent;
    }
    &::-webkit-scrollbar-thumb: {
      backgroundcolor: rgb(192, 192, 192);
    }

    ${(p) =>
      p.isExpanded &&
      css`
        max-height: 26rem !important;
        /* padding: 16px 24px !important; */
        &:last-child {
          padding-bottom: 1rem;
        }
      `}
  }
`

const StyledWrapper = styled(Wrapper)`
  padding: 0;
  flex-direction: column;
  overflow: hidden;
  background: ${(p) => p.theme.palette.background.primary};
`

function Cameras({ cameras }) {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpansion = () => setIsExpanded(!isExpanded)

  const [cameraModalOpen, setCameraModalOpen] = useState(false)
  const [currentCamera, setCurrentCamera] = useState(null)

  const openModal = (camera) => {
    setCameraModalOpen(true)
    setCurrentCamera(camera)
  }
  const closeModal = () => {
    setCameraModalOpen(false)
    setCurrentCamera(null)
  }

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <StyledWrapper>
        <StyledHeader
          avatar={<VideocamIcon />}
          title={
            <Typography
              color="inherit"
              style={{
                fontSize: '1.2rem',
              }}
            >
              {cameras && cameras.length < 1
                ? 'Отсутствуют камеры наружного наблюдения'
                : 'Камеры наружного наблюдения'}
            </Typography>
          }
        />
        <StyledContent isExpanded={isExpanded}>
          <List>
            {cameras ? (
              cameras.length > 0 ? (
                cameras.map((camera) => (
                  <Camera
                    onClick={() => openModal(camera)}
                    key={camera.uuid}
                    {...camera}
                  />
                ))
              ) : (
                <>
                  <Typography align="center">
                    Не привязано ни одной камеры :(
                  </Typography>
                </>
              )
            ) : (
              <Loader msg="Загружаем список камер" />
            )}
          </List>
        </StyledContent>
      </StyledWrapper>

      <CameraModal
        open={cameraModalOpen}
        handleClose={closeModal}
        camera={currentCamera}
      />
      {cameras && cameras.length > 0 && (
        <Tooltip placement="bottom" title="Отобразить список">
          <FoldButton isExpanded={isExpanded} onClick={toggleExpansion} />
        </Tooltip>
      )}
    </div>
  )
}

const mapStateToProps = ({ selected: { entry }, devices: { cameras } }) => ({
  entry,
  cameras,
})

export default connect(mapStateToProps)(Cameras)
