import React from 'react'
import { Button, Select, Loader } from 'sputnik-ui'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Zoom from '@material-ui/core/Zoom'

import {
  getUnlinkedIntercoms,
  linkDevice,
  getIntercomsByAddress,
} from 'store/operations'

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

class AttachNewIntercomModal extends React.Component {
  state = { selected: null }

  componentDidMount() {
    this.props.dispatch(getUnlinkedIntercoms('', true))
  }

  handleSelect = selected => this.setState({ selected })

  attachIntercom = () => {
    const {
      dispatch,
      match: {
        params: { entry, house },
      },
    } = this.props

    dispatch(
      linkDevice({
        house_uuid: house,
        entry_uuid: entry,
        device_uuid: this.state.selected.value,
        device_type: 'intercom',
      }),
    ).then(() => {
      if (entry) {
        dispatch(getIntercomsByAddress(entry))
      }
      if (house) {
        dispatch(getIntercomsByAddress(house))
      }
      this.props.dispatch(getUnlinkedIntercoms('', true))
      this.setState({ selected: null })
      this.props.handleClose()
    })
  }

  devicesPromise = q => {
    return new Promise((resolve, reject) =>
      this.props
        .dispatch(getUnlinkedIntercoms(q, true))
        .then(unlinkedIntercoms => {
          const options = unlinkedIntercoms.map(x => ({
            label: x.serial_number,
            value: x.device_uuid,
          }))
          resolve(options)
        })
        .catch(reject),
    )
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        TransitionComponent={Transition}
        onClose={this.props.handleClose}
        PaperProps={{ style: { overflow: 'visible' } }}
      >
        <DialogTitle>Привязать новую панель</DialogTitle>
        <DialogContent style={{ overflow: 'visible' }}>
          {this.props.unlinkedIntercoms ? (
            <Select
              // options={this.props.unlinkedIntercoms.map(x => ({
              //   label: x.number,
              //   value: x.uuid,
              // }))}
              handleSelect={this.handleSelect}
              selected={this.state.selected}
              placeholder="Выберите панель"
              isMulti={false}
              autoFocus
              async
              loadOptions={this.devicesPromise}
              cacheOptions
              defaultOptions
            />
          ) : (
            <Loader
              msg="Загружаем список непривязанных панелей..."
              error={this.props.error}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} rounded>
            отмена
          </Button>
          <Button onClick={this.attachIntercom} primary rounded>
            подтвердить
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = ({
  devices: { unlinkedIntercoms, error },
  selected: { entry },
}) => ({
  unlinkedIntercoms,
  error,
  entry,
})

export default connect(mapStateToProps)(withRouter(AttachNewIntercomModal))
