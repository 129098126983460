export default theme => ({
  summary: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: theme.palette.primary['main'],
  },
  heading: {
    color: theme.palette.primary.contrastText,
  },
  card: {
    // boxShadow: '0 4px 12px 0.5px rgba(0,0,0,0.08)',
    '&:hover': {
      '.card-header': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    paddingBottom: '1rem',
    overflow: 'hidden',
  },
  cardTitle: {
    // color: theme.palette.primary['contrastText'],
    fontSize: '1.2rem',
  },
  whiteHeader: {
    backgroundColor: 'white',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    position: 'sticky',
    zIndex: 2,
    top: 0,
    color: theme.palette.primary.contrastText,
  },
  searchHeader: {
    height: 80,
    color: theme.palette.primary.contrastText,
  },
  cardAction: {
    marginTop: 0,
    marginRight: -8,
  },
  searchAction: {
    width: '100%',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: 8,
  },
  cardContent: {
    overflowY: 'scroll',
    maxHeight: 'calc(100% - 1.625rem * 3)',
    paddingLeft: 0,
    paddingRight: 0,
    '&::-webkit-scrollbar': {
      width: '3px',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(192, 192, 192)',
    },
  },
  list: {
    padding: 0,
  },
  listItem: {},
  listAction: {
    marginRight: '1rem',
  },
})
