import React from 'react'
import { connect } from 'react-redux'
import { Loader } from 'sputnik-ui'
import { useParams, withRouter } from 'react-router-dom'
import { Card, withSearch } from 'components'
import RemoveFlatModal from 'components/organisms/RemoveFlatModal'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import GroupIcon from '@material-ui/icons/Group'
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'

import { getCitizens } from 'store/operations'
import { Tooltip } from '@material-ui/core'

const searchCitizens = (props, query) => {
  const { dispatch } = props

  const flat = props.selected.flat.uuid
  const q = query || null

  dispatch(
    getCitizens({
      flat,
      q,
    })
  )
}

const Citizens = ({
  citizens,
  err,
  dispatch,
  history,
  toggleDrawer,
  confirmAction,
  ...props
}) => {
  const { flat: uuid } = useParams()
  const [removeFlatsModal, setremoveFlatsModal] = React.useState(false)
  if (!citizens) {
    return <Loader msg="Загружаем список жителей..." err={err} fluid />
  }

  const handleDeleteFlat = () => {
    setremoveFlatsModal(true)
  }

  return (
    <>
      <Card
        title="Список жителей"
        right="Удалить квартиру"
        titleIcon={<GroupIcon />}
        rightClick={handleDeleteFlat}
        {...props}
      >
        {citizens.map((_) => (
          <ListItem key={_.uuid}>
            <ListItemIcon>
              <SentimentVerySatisfiedIcon />
            </ListItemIcon>
            <ListItemText
              primary={`${_.surname} ${_.name}`}
              secondary={_.sip_login}
            />
            <ListItemSecondaryAction>
              <Tooltip placement="top" title="Отвязать жителя">
                <IconButton onClick={confirmAction(_.id)}>
                  <ClearIcon color="error" />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </Card>
      <RemoveFlatModal
        open={removeFlatsModal}
        handleClose={() => setremoveFlatsModal(false)}
        uuid={uuid}
      />
    </>
  )
}

const mapStateToProps = ({ resources }) => ({
  citizens: resources.current && resources.current.citizens,
  err: resources.err,
})

let toExp = withSearch(searchCitizens)(Citizens)
toExp = withRouter(toExp)

export default connect(mapStateToProps)(toExp)
