import React from 'react'
import { connect } from 'react-redux'
import { Wrapper } from 'sputnik-ui'

import CardHeader from '@material-ui/core/CardHeader'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode'
import PhonelinkEraseIcon from '@material-ui/icons/PhonelinkErase'
import DateRange from '@material-ui/icons/DateRange'

import LinkedCameraIcon from '@material-ui/icons/LinkedCamera'
import DialerSipIcon from '@material-ui/icons/DialerSip'

import { getCamera } from 'store/operations'

const styles = (theme) => ({
  heading: {
    color: theme.palette.primary.contrastText,
  },
  subheader: {
    fontSize: '0.9rem',
    fontWeight: 400,
    color: theme.palette.primary.contrastText,
    display: 'inline-flex',
  },
  cardTitle: {
    fontSize: '1.2rem',
    fontWeight: 400,
    color: theme.palette.primary.contrastText,
  },
  cardHeader: {
    backgroundColor: theme.palette.primary['main'],
    color: theme.palette.primary.contrastText,
  },
  cardAction: {
    marginTop: 0,
    marginRight: -8,
  },
  cardContent: {
    overflowY: 'scroll',
    maxHeight: 450,
  },
  list: {
    padding: 0,
  },
  listItem: {},
  listAction: {
    marginRight: '1rem',
  },
})

const IntercomDevice = ({
  device,
  classes,
  action,
  dispatch,
  openCameraModal,
  handleUnlink,
}) => {
  return (
    <Wrapper
      style={{ flexDirection: 'column', padding: 0, overflow: 'hidden' }}
    >
      <CardHeader
        avatar={<DeveloperModeIcon />}
        title={
          <Typography
            variant="subheading"
            className={classes.cardTitle}
            display="block"
          >
            {`${device?.serial_number}`}{' '}
          </Typography>
        }
        subheader={
          <Typography variant="title" className={classes.subheader}>
            <DialerSipIcon
              style={{
                width: '0.9rem',
                height: '0.9rem',
                marginRight: '0.125rem',
              }}
            />{' '}
            {device.sip_login}
            {' · '}
            <DateRange
              style={{
                width: '0.9rem',
                height: '0.9rem',

                marginLeft: '0.125rem',
              }}
            />
            {new Date(device.close_collect_keys).toLocaleDateString()}
          </Typography>
        }
        action={
          <React.Fragment>
            <Tooltip title="Открепить устройство" placement="bottom">
              <IconButton onClick={handleUnlink(device)}>
                <PhonelinkEraseIcon color="error" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Привязанная камера" placement="bottom">
              <IconButton
                onMouseEnter={() => dispatch(getCamera(device.id))}
                onClick={openCameraModal(device)}
                color="inherit"
              >
                <LinkedCameraIcon />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        }
        className={classes.cardHeader}
        classes={{
          action: classes.cardAction,
        }}
      />
    </Wrapper>
  )
}

export default connect()(withStyles(styles)(IntercomDevice))
