/* eslint-disable */

import config from 'config'

/**
 *
 *
 * @export
 * @class Api
 */
class Api {
  /**
   * Creates an instance of Api.
   * @param {*} baseUri
   * @memberof Api
   */
  constructor(baseUri) {
    this.baseUri = baseUri
  }

  /**
   * Makes a redirect to the SSO if err status is 401, returns a Promise.reject
   *
   * @param {*} err
   * @returns {Promise<Response>}
   * @memberof Api
   */
  _handleError(err: any): Promise<Response> {
    if (err.status === 401) {
      sessionStorage.removeItem('auth')
      window.location = `${config.redirectUri}/auth/sso?origin=${window.location.href}`
      return Promise.reject()
    }
    if (err.status === 500) {
      const data = {
        message: 'Произошла непредвиденная ошибка (Код статуса: 500)',
      }
      const blob = new Blob([JSON.stringify(data, null, 2)], {
        type: 'application/json',
      })

      const init = { status: err.status, statusText: err.statusText }
      const myResponse = new Response(blob, init)

      return Promise.reject(myResponse)
    }
    return Promise.reject(err)
  }

  /**
   * Makes an api request, handles error status 401
   *
   * @param {string} endpoint - the endpoint, that gets appended to the (default) baseUri
   * @param {?Object} [options={}] - custom request options, override default ones
   * @param {?string} baseUri - custom baseUri
   * @returns {Promise<any>}
   * @memberof Api
   */
  request(
    endpoint: string,
    options: ?Object = {},
    baseUri: ?string,
  ): Promise<any> {
    const BASE_URI: string = baseUri || this.baseUri

    const request = new Request(`${BASE_URI}${endpoint}`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      credentials: 'include',
      ...options,
    })

    return fetch(request)
      .then(r => (r.status <= 200 ? r.json() : Promise.reject(r)))
      .catch(this._handleError)
  }
}

const api = new Api(config.baseUri)

export default api
