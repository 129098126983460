import * as types from './types'

export const clearStatus = () => ({
  type: types.CLEAR_STATUS,
})

export const showInfoMessage = msg => ({
  type: types.SHOW_INFO_MESSAGE,
  payload: { msg },
})
export const hideInfoMessage = () => ({
  type: types.HIDE_INFO_MESSAGE,
})
