import React from 'react'
import { Button, useInput } from 'sputnik-ui'
import { connect } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'

import Zoom from '@material-ui/core/Zoom'

import { addKey } from 'store/operations'
import { getKeys } from '../../../store/keys/operations'

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

function AddKeyModal({ entry, dispatch, open, handleClose }) {
  const { value: newKey, bind: bindKeyInput, reset } = useInput('')
  const addNewKey = () =>
    dispatch(addKey(entry.uuid, newKey))
      .then(() => dispatch(getKeys(entry.uuid)))
      .then(() => {
        reset()
        handleClose()
      })

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      PaperProps={{ style: { minWidth: 350 } }}
    >
      <DialogTitle>Добавление ключа</DialogTitle>
      <DialogContent>
        <TextField
          placeholder="hex ключа"
          style={{ width: '100%' }}
          autoFocus
          {...bindKeyInput}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} rounded secondary>
          отмена
        </Button>
        <Button onClick={addNewKey} disabled={!newKey} rounded primary>
          Добавить
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = ({ resources: { current } }) => ({
  entry: current,
})

export default connect(mapStateToProps)(AddKeyModal)
