import React from 'react'
import { Button, Loader } from 'sputnik-ui'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Zoom from '@material-ui/core/Zoom'
import {
  unlinkIntercom,
  getIntercomsByAddress,
  getUnlinkedIntercoms,
} from 'store/operations'

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

class UnlinkDeviceModal extends React.Component {
  handleUnlink = () => {
    const {
      dispatch,
      handleClose,
      device,
      match: {
        params: { entry, house },
      },
    } = this.props
    dispatch(unlinkIntercom(device.uuid, house)).then(() => {
      dispatch(getIntercomsByAddress(entry))
      dispatch(getUnlinkedIntercoms('', true))
      handleClose()
    })
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.props.handleClose}
      >
        {this.props.device ? (
          <React.Fragment>
            <DialogTitle>
              {`Вы действительно хотите отвязать устройство #${this.props.device.number}?`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Это действие нельзя будет отменить
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.handleClose} rounded>
                отмена
              </Button>
              <Button onClick={this.handleUnlink} rounded color="danger">
                подтвердить
              </Button>
            </DialogActions>
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </Dialog>
    )
  }
}

export default connect()(withRouter(UnlinkDeviceModal))
