//@flow
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from 'sputnik-ui'
import { connect } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Zoom from '@material-ui/core/Zoom'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { linkDevice } from 'store/operations'

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

type Props = {
  match: Object,
  dispatch: Function,
  Header: React.ComponentType<{}>,
  history: Object,
  handleClose: Function,
  open: boolean,
  error: string,
}

type State = {
  address: string,
  entry: string,
  identifier: string,
  firstFlat: string,
  lastFlat: string,
  forceAddress: boolean,
  country: string,
  region: string,
  locality: string,
  street: string,
  house: string,
}

const MAX = 10001
const MIN = 1

class AddAddressPage extends React.Component<Props, State> {
  state = {
    address: '',
    entry: '',
    identifier: '',
    firstFlat: '',
    lastFlat: '',
    forceAddress: false,
    custom_numbering: false,
    country: '',
    locality: '',
    street: '',
    house: '',
    region: '',
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleChange = (name) => (e) => {
    console.log(e.target.value)
    this.setState({
      [name]: e.target.value,
    })
  }

  handleSubmit = () => {
    const {
      address,
      entry,
      identifier,
      firstFlat,
      lastFlat,
      forceAddress,
      custom_numbering,
      country,
      region,
      locality,
      street,
      house,
    } = this.state

    // TODO: handle "forceAdress"

    const commonProps = {
      custom_numbering,
      identifier,
      entry,
      first_flat: firstFlat,
      last_flat: lastFlat,
    }

    const forceAddressProps = {
      country,
      region,
      locality,
      street,
      house,
      ignore_address_error: true,
    }

    let props = {}

    if (!forceAddress) {
      props = {
        ...commonProps,
        address,
      }
    } else {
      props = {
        ...commonProps,
        ...forceAddressProps,
      }
    }

    this.props
      .dispatch(linkDevice(props))
      .then(() => this.props.history.push('/'))
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        TransitionComponent={Transition}
        onClose={this.props.handleClose}
        PaperProps={{ style: { overflow: 'visible' } }}
        scroll="body"
      >
        <DialogTitle>Добавление адреса</DialogTitle>
        <DialogContent style={styles.content}>
          <Typography align="center">
            Вводите адрес через запятую, начиная со страны.{' '}
            {!this.state.forceAddress && 'Не бойтесь ошибиться 😉'}
          </Typography>
          <Typography align="center">
            Такие параметры как регион или область опциональны.
          </Typography>

          {this.state.forceAddress && (
            <Typography align="center" variant="subtitle2">
              <span role="img" aria-label="danger">
                🛑
              </span>
              Мы не будем проверять введеный вами адрес, убедитесь в
              правильности его введения{' '}
            </Typography>
          )}
          <div style={styles.checkArea}>
            {' '}
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.forceAddress}
                  onChange={(e) =>
                    this.setState({ forceAddress: e.target.checked })
                  }
                />
              }
              label="Расширенный ввод адреса"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.custom_numbering}
                  onChange={(e) =>
                    this.setState({ custom_numbering: e.target.checked })
                  }
                />
              }
              label="Отключить валидацию диапазона"
            />
          </div>

        
          <form style={styles.form}>
            {this.state.forceAddress ? (
              <React.Fragment>
                <TextField
                  fullWidth
                  label="Страна"
                  value={this.state.country}
                  onChange={this.handleChange('country')}
                  placeholder="Россия"
                  margin="dense"
                  autoFocus
                />

                <TextField
                  fullWidth
                  label="Регион"
                  value={this.state.region}
                  onChange={this.handleChange('region')}
                  placeholder="Татарстан"
                  margin="dense"
                />

                <TextField
                  fullWidth
                  label="Город"
                  value={this.state.locality}
                  onChange={this.handleChange('locality')}
                  placeholder="Казань"
                  margin="dense"
                />

                <TextField
                  fullWidth
                  label="Улица"
                  value={this.state.street}
                  onChange={this.handleChange('street')}
                  placeholder="Алексея Козина"
                  margin="dense"
                />

                <TextField
                  fullWidth
                  label="Дом"
                  value={this.state.house}
                  onChange={this.handleChange('house')}
                  placeholder="3а"
                  margin="dense"
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <TextField
                  fullWidth
                  label="Адрес"
                  value={this.state.address}
                  onChange={this.handleChange('address')}
                  placeholder="Россия, Республика Татарстан, Казань, Козина 3а"
                  margin="dense"
                  autoFocus
                />
              </React.Fragment>
            )}
            <TextField
              fullWidth
              label="Подъезд"
              value={this.state.entry}
              onChange={this.handleChange('entry')}
              placeholder="Номер подъезда"
              type="number"
              margin="dense"
            />

            <TextField
              value={this.state.identifier}
              fullWidth
              label="Устройство"
              onChange={this.handleChange('identifier')}
              placeholder="Серийный номер или номер материнской платы"
              margin="dense"
            />

            <Typography
              style={{
                marginTop: '1rem',
              }}
            >
              Диапазон квартир
            </Typography>
            <div style={styles.flatRangeWrapper}>
              <TextField
                value={this.state.firstFlat}
                type="number"
                onChange={this.handleChange('firstFlat')}
                inputProps={{
                  style: styles.flatRangeInput,
                  min: MIN,
                  step: 1,
                  max: MAX - 1,
                }}
                placeholder="первая"
              />
              <div style={styles.divider} />
              <TextField
                value={this.state.lastFlat}
                type="number"
                onChange={this.handleChange('lastFlat')}
                placeholder="последняя"
                inputProps={{
                  style: styles.flatRangeInput,
                  min: MIN + 1,
                  step: '1',
                  max: MAX,
                }}
              />
            </div>
          </form>
          {this.props.error && (
            <Typography
              align="center"
              color="error"
              style={{ marginTop: '1rem' }}
            >
              {this.props.error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.handleClose}
            secondary
            rounded
            tabIndex={-1}
          >
            отмена
          </Button>
          <Button primary rounded onClick={this.handleSubmit}>
            Добавить
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const styles = {
  content: { overflow: 'visible' }, // maxWidth: 500
  checkArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submitBtn: { marginLeft: 'auto', marginTop: 16 },
  input: {},
  divider: {
    backgroundColor: 'black',
    width: 30,
    height: 2,
    marginRight: '0.625rem',
    marginLeft: '0.625rem',
  },
  flatRangeWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flatRangeInput: {
    textAlign: 'center',
    minWidth: '100px',
  },
}

const mapStateToProps = ({ devices }) => ({
  error: devices.error,
})

export default connect(mapStateToProps)(withRouter(AddAddressPage))
