import * as types from './types'

export const clearResources = () => ({
  type: types.CLEAR_RESOURCES,
})
export const clearEntries = () => ({
  type: types.CLEAR_ENTRIES,
})

/*  countries  */

export const getCountriesRequest = () => ({
  type: types.GET_COUNTRIES_REQUEST,
})
export const getCountriesSuccess = (countries) => ({
  type: types.GET_COUNTRIES_SUCCESS,
  payload: countries,
})
export const getCountriesError = (error) => ({
  type: types.GET_COUNTRIES_ERROR,
  payload: error,
})

export const fetchCountryRequest = () => ({
  type: types.FETCH_COUNTRY_REQUEST,
})
export const fetchCountrySuccess = (country) => ({
  type: types.FETCH_COUNTRY_SUCCESS,
  payload: { country },
})
export const fetchCountryFailure = (error) => ({
  type: types.FETCH_COUNTRY_FAILURE,
  payload: { error },
})

/* regions */

export const getRegionsRequest = () => ({
  type: types.GET_REGIONS_REQUEST,
})
export const getRegionsSuccess = (regions) => ({
  type: types.GET_REGIONS_SUCCESS,
  payload: regions,
})
export const getRegionsError = (error) => ({
  type: types.GET_REGIONS_ERROR,
  payload: error,
})

export const fetchRegionRequest = () => ({
  type: types.FETCH_REGION_REQUEST,
})
export const fetchRegionSuccess = (region) => ({
  type: types.FETCH_REGION_SUCCESS,
  payload: { region },
})
export const fetchRegionFailure = (error) => ({
  type: types.FETCH_REGION_FAILURE,
  payload: { error },
})

/* localities */

export const getLocalitiesRequest = () => ({
  type: types.GET_LOCALITIES_REQUEST,
})
export const getLocalitiesSuccess = (localities) => ({
  type: types.GET_LOCALITIES_SUCCESS,
  payload: localities,
})
export const getLocalitiesError = (error) => ({
  type: types.GET_LOCALITIES_ERROR,
  payload: error,
})

export const fetchLocalityRequest = () => ({
  type: types.FETCH_LOCALITY_REQUEST,
})
export const fetchLocalitySuccess = (locality) => ({
  type: types.FETCH_LOCALITY_SUCCESS,
  payload: { locality },
})
export const fetchLocalityFailure = (error) => ({
  type: types.FETCH_LOCALITY_FAILURE,
  payload: { error },
})

/* areas */

export const getAreasRequest = () => ({
  type: types.GET_AREAS_REQUEST,
})
export const getAreasSuccess = (areas) => ({
  type: types.GET_AREAS_SUCCESS,
  payload: areas,
})
export const getAreasError = (error) => ({
  type: types.GET_AREAS_ERROR,
  payload: error,
})

export const fetchAreaRequest = () => ({
  type: types.FETCH_AREA_REQUEST,
})
export const fetchAreaSuccess = (area) => ({
  type: types.FETCH_AREA_SUCCESS,
  payload: { area },
})
export const fetchAreaFailure = (error) => ({
  type: types.FETCH_AREA_FAILURE,
  payload: { error },
})

/* streets */

export const getStreetsRequest = () => ({
  type: types.GET_STREETS_REQUEST,
})
export const getStreetsSuccess = (streets) => ({
  type: types.GET_STREETS_SUCCESS,
  payload: streets,
})
export const getStreetsError = (error) => ({
  type: types.GET_STREETS_ERROR,
  payload: error,
})

export const fetchStreetRequest = () => ({
  type: types.FETCH_STREET_REQUEST,
})
export const fetchStreetSuccess = (street) => ({
  type: types.FETCH_STREET_SUCCESS,
  payload: { street },
})
export const fetchStreetFailure = (error) => ({
  type: types.FETCH_STREET_FAILURE,
  payload: { error },
})

/* houses */

export const getHousesRequest = () => ({
  type: types.GET_HOUSES_REQUEST,
})
export const getHousesSuccess = (houses) => ({
  type: types.GET_HOUSES_SUCCESS,
  payload: houses,
})
export const getHousesError = (error) => ({
  type: types.GET_HOUSES_ERROR,
  payload: error,
})

export const fetchHouseRequest = () => ({
  type: types.FETCH_HOUSE_REQUEST,
})
export const fetchHouseSuccess = (house) => ({
  type: types.FETCH_HOUSE_SUCCESS,
  payload: { house },
})
export const fetchHouseFailure = (error) => ({
  type: types.FETCH_HOUSE_FAILURE,
  payload: { error },
})

/* entries */

export const getEntriesRequest = () => ({
  type: types.GET_ENTRIES_REQUEST,
})
export const getEntriesSuccess = (entries) => ({
  type: types.GET_ENTRIES_SUCCESS,
  payload: entries,
})
export const getEntriesError = (error) => ({
  type: types.GET_ENTRIES_ERROR,
  payload: error,
})

export const fetchEntryRequest = () => ({
  type: types.FETCH_ENTRY_REQUEST,
})
export const fetchEntrySuccess = (entry) => ({
  type: types.FETCH_ENTRY_SUCCESS,
  payload: { entry },
})
export const fetchEntryFailure = (error) => ({
  type: types.FETCH_ENTRY_FAILURE,
  payload: { error },
})

/* flats */

export const getFlatsRequest = () => ({
  type: types.GET_FLATS_REQUEST,
})
export const getFlatsSuccess = (flats) => ({
  type: types.GET_FLATS_SUCCESS,
  payload: flats,
})
export const getFlatsError = (error) => ({
  type: types.GET_FLATS_ERROR,
  payload: error,
})

export const changeFlatRangeRequest = () => ({
  type: types.CHANGE_FLAT_RANGE_REQUEST,
})
export const changeFlatRangeSuccess = (msg) => ({
  type: types.CHANGE_FLAT_RANGE_SUCCESS,
  payload: msg,
})
export const changeFlatRangeError = (error) => ({
  type: types.CHANGE_FLAT_RANGE_ERROR,
  payload: error,
})

export const fetchFlatRequest = () => ({
  type: types.FETCH_FLAT_REQUEST,
})
export const fetchFlatSuccess = (flat) => ({
  type: types.FETCH_FLAT_SUCCESS,
  payload: { flat },
})
export const fetchFlatFailure = (error) => ({
  type: types.FETCH_FLAT_FAILURE,
  payload: { error },
})

/* citizens */

export const getCitizensRequest = () => ({
  type: types.GET_CITIZENS_REQUEST,
})
export const getCitizensSuccess = (citizens) => ({
  type: types.GET_CITIZENS_SUCCESS,
  payload: citizens,
})
export const getCitizensError = (error) => ({
  type: types.GET_CITIZENS_ERROR,
  payload: error,
})

export const unlinkCitizenRequest = () => ({
  type: types.UNLINK_CITIZEN_REQUEST,
})
export const unlinkCitizenSuccess = () => ({
  type: types.UNLINK_CITIZEN_SUCCESS,
})
export const unlinkCitizenError = (error) => ({
  type: types.UNLINK_CITIZEN_ERROR,
  payload: error,
})

// /*  delete Flats */ delete Flats
export const removeFlatRequest = () => ({
  type: types.REMOVE_FLAT,
})
export const removeFlatSuccess = () => ({
  type: types.REMOVE_FLAT_SUCCESS,
})
export const removeFlatError = (error) => ({
  type: types.REMOVE_FLAT_FAILURE,
  payload: error,
})
