import * as types from './types'

const initialState = {
  msg: null,
}

export default function ui(state = initialState, { type, payload }) {
  switch (type) {
    case types.SHOW_INFO_MESSAGE:
      return { ...state, msg: payload.msg }
    case types.HIDE_INFO_MESSAGE:
      return { ...state, msg: payload.msg }
    case types.CLEAR_STATUS:
      return { ...state, msg: null }
    default:
      return state
  }
}
