import * as actions from './actions'
import Api from 'utils/api'

export const getKeys = (entry, page = 1, limit) => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.getKeysRequest())
  try {
    const keys = await Api.request(
      `/key_owners/${entry}/keys?page=${page}&limit=${limit}`,
    )

    dispatch(actions.getKeysSuccess(keys, page === 1))
    return Promise.resolve()
  } catch (err) {
    dispatch(actions.getKeysError(err))
    return Promise.reject()
  }
}

export const removeKey = (entry, key) => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.removeKeyRequest())
  try {
    await Api.request(
      `/key_owners/${entry}/keys/${key}
`,
      { method: 'DELETE' },
    )
    dispatch(actions.removeKeySuccess(`Ключ ${key} был успешно удален`))
    return Promise.resolve()
  } catch (err) {
    dispatch(
      actions.removeKeyError(`При удалении ключа ${key} произошла ошибка`),
    )
    return Promise.reject()
  }
}

export const addKey = (entry, key) => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.addKeyRequest())
  try {
    await Api.request(`/key_owners/${entry}/keys`, {
      method: 'POST',
      body: JSON.stringify({ data: key }),
    })
    dispatch(actions.addKeySuccess(`Ключ ${key} был успешно добавлен`))

    return Promise.resolve()
  } catch (err) {
    dispatch(
      actions.addKeyError(`При добавлении ключа ${key} произошла ошибка`),
    )
    return Promise.reject()
  }
}

export const deleteAllKeys = (entry: string) => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.deleteAllKeysRequest())
  try {
    await Api.request(`/key_owners/${entry}/keys/destroy_all`, {
      method: 'DELETE',
    })
    dispatch(actions.deleteAllKeysSuccess('Ключи были успешно удалены'))
    return Promise.resolve()
  } catch (err) {
    const msg = 'При удалении ключей произошла ошибка'
    dispatch(actions.deleteAllKeysError(msg))
    return Promise.reject(msg)
  }
}

export const syncKeys = (addressUuid: string) => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.syncKeysRequest())
  try {
    await Api.request(`/key_owners/${addressUuid}/keys/sync`, {
      method: 'PATCH',
    })
    dispatch(actions.syncKeysSuccess('Ключи были успешно синхронизированы'))
    return Promise.resolve()
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.syncKeysError(message))
    return Promise.reject(err)
  }
}
