import * as types from './types'
import { CLEAR_STATUS } from '../ui/types.js'
import { CLEAR_RESOURCES } from '../resources/types.js'

const initialState = {
  msg: '',
  error: '',
}

export default function citizens(state = initialState, { type, payload }) {
  switch (type) {
    case types.SEARCH_FAILURE:
      return { ...state, error: payload.error }
    case types.UNLINK_BY_PHONE_SUCCESS:
      return { ...state, msg: payload.msg }
    case CLEAR_RESOURCES:
      return initialState
    case CLEAR_STATUS:
      return { ...state, msg: null, error: null }
    default:
      return state
  }
}
