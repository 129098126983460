//@flow

export const CLEAR_RESOURCES: string = 'resources/CLEAR'
export const CLEAR_ENTRIES: string = 'resources/CLEAR_ENTRIES'

/* countries */
export const GET_COUNTRIES_REQUEST: string =
  'resources/countries/GET_COUNTRIES_REQUEST'
export const GET_COUNTRIES_SUCCESS: string =
  'resources/countries/GET_COUNTRIES_SUCCESS'
export const GET_COUNTRIES_ERROR: string =
  'resources/countries/GET_COUNTRIES_ERROR'

/* regions */
export const GET_REGIONS_REQUEST: string =
  'resources/regions/GET_REGIONS_REQUEST'
export const GET_REGIONS_SUCCESS: string =
  'resources/regions/GET_REGIONS_SUCCESS'
export const GET_REGIONS_ERROR: string = 'resources/regions/GET_REGIONS_ERROR'

/* localities */
export const GET_LOCALITIES_REQUEST: string =
  'resources/localities/GET_LOCALITIES_REQUEST'
export const GET_LOCALITIES_SUCCESS: string =
  'resources/localities/GET_LOCALITIES_SUCCESS'
export const GET_LOCALITIES_ERROR: string =
  'resources/localities/GET_LOCALITIES_ERROR'

/* areas */
export const GET_AREAS_REQUEST: string = 'resources/areas/GET_AREAS_REQUEST'
export const GET_AREAS_SUCCESS: string = 'resources/areas/GET_AREAS_SUCCESS'
export const GET_AREAS_ERROR: string = 'resources/areas/GET_AREAS_ERROR'

/* streets */
export const GET_STREETS_REQUEST: string =
  'resources/streets/GET_STREETS_REQUEST'
export const GET_STREETS_SUCCESS: string =
  'resources/streets/GET_STREETS_SUCCESS'
export const GET_STREETS_ERROR: string = 'resources/streets/GET_STREETS_ERROR'

/* houses */
export const GET_HOUSES_REQUEST: string = 'resources/houses/GET_HOUSES_REQUEST'
export const GET_HOUSES_SUCCESS: string = 'resources/houses/GET_HOUSES_SUCCESS'
export const GET_HOUSES_ERROR: string = 'resources/houses/GET_HOUSES_ERROR'

/* entries */
export const GET_ENTRIES_REQUEST: string =
  'resources/entries/GET_ENTRIES_REQUEST'
export const GET_ENTRIES_SUCCESS: string =
  'resources/entries/GET_ENTRIES_SUCCESS'
export const GET_ENTRIES_ERROR: string = 'resources/entries/GET_ENTRIES_ERROR'

export const GET_ENTRY_REQUEST: string = 'resources/entries/GET_ENTRY_REQUEST'
export const GET_ENTRY_SUCCESS: string = 'resources/entries/GET_ENTRY_SUCCESS'
export const GET_ENTRY_ERROR: string = 'resources/entries/GET_ENTRY_ERROR'

/* flats */
export const GET_FLATS_REQUEST: string = 'resources/entries/GET_FLATS_REQUEST'
export const GET_FLATS_SUCCESS: string = 'resources/entries/GET_FLATS_SUCCESS'
export const GET_FLATS_ERROR: string = 'resources/entries/GET_FLATS_ERROR'

export const CHANGE_FLAT_RANGE_REQUEST: string =
  'resources/entries/CHANGE_FLAT_RANGE_REQUEST'
export const CHANGE_FLAT_RANGE_SUCCESS: string =
  'resources/entries/CHANGE_FLAT_RANGE_SUCCESS'
export const CHANGE_FLAT_RANGE_ERROR: string =
  'resources/entries/CHANGE_FLAT_RANGE_ERROR'

/* citizens */
export const GET_CITIZENS_REQUEST: string =
  'resources/citizens/GET_CITIZENS_REQUEST'
export const GET_CITIZENS_SUCCESS: string =
  'resources/citizens/GET_CITIZENS_SUCCESS'
export const GET_CITIZENS_ERROR: string =
  'resources/citizens/GET_CITIZENS_ERROR'

export const UNLINK_CITIZEN_REQUEST: string = 'resources/UNLINK_CITIZEN_REQUEST'
export const UNLINK_CITIZEN_SUCCESS: string = 'resources/UNLINK_CITIZEN_SUCCESS'
// export const UNLINK_CITIZEN_FAILURE: string = 'resources/UNLINK_CITIZEN_FAILURE'
export const UNLINK_CITIZEN_ERROR: string = 'resources/UNLINK_CITIZEN_ERROR'

// // NEW

// /* countries */
export const FETCH_COUNTRY_REQUEST: string =
  'resources/countries/FETCH_COUNTRY_REQUEST'
export const FETCH_COUNTRY_SUCCESS: string =
  'resources/countries/FETCH_COUNTRY_SUCCESS'
export const FETCH_COUNTRY_FAILURE: string =
  'resources/countries/FETCH_COUNTRY_FAILURE'

// /* regions */
export const FETCH_REGION_REQUEST: string =
  'resources/regions/FETCH_REGION_REQUEST'
export const FETCH_REGION_SUCCESS: string =
  'resources/regions/FETCH_REGION_SUCCESS'
export const FETCH_REGION_FAILURE: string =
  'resources/regions/FETCH_REGION_FAILURE'

// /* localities */
export const FETCH_LOCALITY_REQUEST: string =
  'resources/localities/FETCH_LOCALITY_REQUEST'
export const FETCH_LOCALITY_SUCCESS: string =
  'resources/localities/FETCH_LOCALITY_SUCCESS'
export const FETCH_LOCALITY_FAILURE: string =
  'resources/localities/FETCH_LOCALITY_FAILURE'

// /* areas */
export const FETCH_AREA_REQUEST: string = 'resources/areas/FETCH_AREA_REQUEST'
export const FETCH_AREA_SUCCESS: string = 'resources/areas/FETCH_AREA_SUCCESS'
export const FETCH_AREA_FAILURE: string = 'resources/areas/FETCH_AREA_FAILURE'

// /* streets */
export const FETCH_STREET_REQUEST: string =
  'resources/streets/FETCH_STREET_REQUEST'
export const FETCH_STREET_SUCCESS: string =
  'resources/streets/FETCH_STREET_SUCCESS'
export const FETCH_STREET_FAILURE: string =
  'resources/streets/FETCH_STREET_FAILURE'

// /* houses */
export const FETCH_HOUSE_REQUEST: string =
  'resources/houses/FETCH_HOUSE_REQUEST'
export const FETCH_HOUSE_SUCCESS: string =
  'resources/houses/FETCH_HOUSE_SUCCESS'
export const FETCH_HOUSE_FAILURE: string =
  'resources/houses/FETCH_HOUSE_FAILURE'

// /* entries */
export const FETCH_ENTRY_REQUEST: string =
  'resources/entries/FETCH_ENTRY_REQUEST'
export const FETCH_ENTRY_SUCCESS: string =
  'resources/entries/FETCH_ENTRY_SUCCESS'
export const FETCH_ENTRY_FAILURE: string =
  'resources/entries/FETCH_ENTRY_FAILURE'

// /* flats */
export const FETCH_FLAT_REQUEST: string = 'resources/entries/FETCH_FLAT_REQUEST'
export const FETCH_FLAT_SUCCESS: string = 'resources/entries/FETCH_FLAT_SUCCESS'
export const FETCH_FLAT_FAILURE: string = 'resources/entries/FETCH_FLAT_FAILURE'

// /*  delete Flats */ delete Flats
export const REMOVE_FLAT = 'resources/REMOVE_FLAT'
export const REMOVE_FLAT_SUCCESS = 'resources/REMOVE_FLAT_SUCCESS'
export const REMOVE_FLAT_FAILURE = 'resources/REMOVE_FLAT_FAILURE'
// export const CHANGE_FLAT_RANGE_REQUEST: string =
//   'resources/entries/CHANGE_FLAT_RANGE_REQUEST'
// export const CHANGE_FLAT_RANGE_SUCCESS: string =
//   'resources/entries/CHANGE_FLAT_RANGE_SUCCESS'
// export const CHANGE_FLAT_RANGE_FAILURE: string =
//   'resources/entries/CHANGE_FLAT_RANGE_FAILURE'
