// import React...
import React, { Suspense } from 'react'
import { Route, Switch, Redirect, NavLink } from 'react-router-dom'
import { Navbar, Toast } from 'sputnik-ui'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Typography, IconButton, Tooltip } from '@material-ui/core'
import { KeyboardArrowRight, Assignment } from '@material-ui/icons'

import { AppTemplate } from 'components'
import requireAuth from '../utils/requireAuth'
import { clearStatus, showInfoMessage } from 'store/ui/actions'

const HomePage = React.lazy(() => import('./pages/HomePage'))
const CountryPage = React.lazy(() => import('./pages/CountryPage'))
const RegionPage = React.lazy(() => import('./pages/RegionPage'))
const AreaPage = React.lazy(() => import('./pages/AreaPage'))
const LocalityPage = React.lazy(() => import('./pages/LocalityPage'))
const StreetPage = React.lazy(() => import('./pages/StreetPage'))
const HousePage = React.lazy(() => import('./pages/HousePage'))
const EntryPage = React.lazy(() => import('./pages/EntryPage'))
const FlatPage = React.lazy(() => import('./pages/FlatPage'))

const LocationWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100% !important;
  width: 100%;
  color: ${p => p.theme.palette.text.secondary};
  white-space: nowrap;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`

const LocationLink = styled(Typography).attrs(p => ({
  style: {
    color: p.theme.palette.text.secondary,
  },
  activeStyle: {
    color: p.theme.palette.text.primary,
  },
  component: NavLink,
}))`
  && {
    text-decoration: none;
    &:hover {
      color: ${p => p.theme.palette.text.primary};
    }
  }
`

const App = ({
  parents,
  currentAddress,
  infoMessage,
  msg,
  error,
  dispatch,
}) => {
  const copyAddress = () => {
    // copy to clipboard
    const el = document.createElement('textarea')
    el.value = currentAddress
    document.body && document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body && document.body.removeChild(el)
    dispatch(showInfoMessage('Адрес был скопирован'))
  }

  return (
    <AppTemplate
      header={
        <Navbar brand app logo fixed shadow user>
          <LocationWrapper>
            {parents &&
              parents.map(({ type, uuid, full_name }, idx) => (
                <span
                  style={{ display: 'inline-flex', alignItems: 'center' }}
                  key={uuid}
                >
                  <LocationLink to={`/${type.slice(0, 1)}/${uuid}`}>
                    {full_name}
                  </LocationLink>
                  {idx !== parents.length - 1 && (
                    <KeyboardArrowRight style={{ margin: '0 .325rem' }} />
                  )}
                </span>
              ))}
            <Tooltip title="Скопировать текущий адрес" placement="bottom">
              <IconButton
                style={{ marginLeft: 'auto', marginRight: -8 }}
                color="inherit"
                onClick={copyAddress}
                disableRipple
              >
                <Assignment />
              </IconButton>
            </Tooltip>
          </LocationWrapper>
        </Navbar>
      }
    >
      <Suspense>
        <Switch>
          <Route component={CountryPage} exact path="/c/:country" />
          <Route component={RegionPage} exact path="/r/:region" />
          <Route component={AreaPage} exact path="/a/:area" />
          <Route component={LocalityPage} exact path="/l/:locality" />
          <Route component={StreetPage} exact path="/s/:street" />
          <Route component={HousePage} exact path="/h/:house" />
          <Route component={EntryPage} exact path="/e/:entry" />
          <Route component={FlatPage} exact path="/f/:flat" />

          <Route component={HomePage} path="/" />

          <Redirect to="/" />
        </Switch>
      </Suspense>

      <Toast
        open={!!infoMessage}
        handleClose={() => dispatch(clearStatus())}
        variant="info"
      >
        {infoMessage}
      </Toast>
      <Toast
        open={!!msg}
        handleClose={() => dispatch(clearStatus())}
        variant="success"
      >
        {msg}
      </Toast>
      <Toast
        open={!!error}
        handleClose={() => dispatch(clearStatus())}
        variant="error"
      >
        {error}
      </Toast>
    </AppTemplate>
  )
}

const mapStateToProps = ({
  resources,
  devices,
  keys,
  citizens,
  ui,
  installations,
}) => ({
  parents: resources.current && resources.current.parents,
  currentAddress: resources.current && resources.current.full_address,
  error:
    devices.error ||
    resources.error ||
    keys.error ||
    citizens.error ||
    installations.error,
  msg:
    devices.msg ||
    resources.msg ||
    keys.msg ||
    citizens.msg ||
    installations.msg,
  infoMessage: ui.msg,
})

export default connect(mapStateToProps)(requireAuth(App))
