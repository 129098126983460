import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Navbar } from 'sputnik-ui'
import getLocation from 'utils/getLocation'

const withHeader = C => {
  const ComponentWithHeader = props => {
    const { match, selected, devices } = props
    const Header = headerProps => (
      <Navbar
        brand
        logo
        app
        location={getLocation(match, selected, devices)}
        shadow
        fixed
        user
        {...headerProps}
      />
    )

    return <C {...props} Header={Header} />
  }

  const mapStateToProps = ({ selected, devices }) => ({
    selected,
    devices: devices.collection,
  })

  return connect(mapStateToProps)(withRouter(ComponentWithHeader))
}

export default withHeader
