import React from 'react'
import { Button } from 'sputnik-ui'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Zoom from '@material-ui/core/Zoom'

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

class ConfirmActionModal extends React.Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.props.handleClose}
      >
        <DialogTitle>
          {`Вы действительно хотите ${this.props.action}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.props.description || 'Это действие нельзя будет отменить'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} rounded>
            отмена
          </Button>
          <Button onClick={this.props.handleAction} rounded color="danger">
            подтвердить
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default ConfirmActionModal
