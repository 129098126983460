//@flow

export const GET_INTERCOMS_BY_ADDRESS_REQUEST: string =
  'devices/GET_INTERCOMS_BY_ADDRESS_REQUEST'
export const GET_INTERCOMS_BY_ADDRESS_SUCCESS: string =
  'devices/GET_INTERCOMS_BY_ADDRESS_SUCCESS'
export const GET_INTERCOMS_BY_ADDRESS_ERROR: string =
  'devices/GET_INTERCOMS_BY_ADDRESS_ERROR'

export const GET_CAMERAS_BY_ADDRESS_REQUEST: string =
  'devices/GET_CAMERAS_BY_ADDRESS_REQUEST'
export const GET_CAMERAS_BY_ADDRESS_SUCCESS: string =
  'devices/GET_CAMERAS_BY_ADDRESS_SUCCESS'
export const GET_CAMERAS_BY_ADDRESS_ERROR: string =
  'devices/GET_CAMERAS_BY_ADDRESS_ERROR'

export const GET_UNLINKED_INTERCOMS_REQUEST: string =
  'devices/GET_UNLINKED_INTERCOMS_REQUEST'
export const GET_UNLINKED_INTERCOMS_SUCCESS: string =
  'devices/GET_UNLINKED_INTERCOMS_SUCCESS'
export const GET_UNLINKED_INTERCOMS_ERROR: string =
  'devices/GET_UNLINKED_INTERCOMS_ERROR'

export const GET_UNLINKED_CAMERAS_REQUEST: string =
  'devices/GET_UNLINKED_CAMERAS_REQUEST'
export const GET_UNLINKED_CAMERAS_SUCCESS: string =
  'devices/GET_UNLINKED_CAMERAS_SUCCESS'
export const GET_UNLINKED_CAMERAS_ERROR: string =
  'devices/GET_UNLINKED_CAMERAS_ERROR'

export const LINK_DEVICE_REQUEST: string = 'devices/LINK_DEVICE_REQUEST'
export const LINK_DEVICE_SUCCESS: string = 'devices/LINK_DEVICE_SUCCESS'
export const LINK_DEVICE_ERROR: string = 'devices/LINK_DEVICE_ERROR'

export const UNLINK_INTERCOM_REQUEST: string = 'devices/UNLINK_INTERCOM_REQUEST'
export const UNLINK_INTERCOM_SUCCESS: string = 'devices/UNLINK_INTERCOM_SUCCESS'
export const UNLINK_INTERCOM_ERROR: string = 'devices/UNLINK_INTERCOM_ERROR'

export const GET_CAMERA_REQUEST: string = 'devices/GET_CAMERA_REQUEST'
export const GET_CAMERA_SUCCESS: string = 'devices/GET_CAMERA_SUCCESS'
export const GET_CAMERA_ERROR: string = 'devices/GET_CAMERA_ERROR'

export const SYNC_INTERCOM_REQUEST: string = 'devices/SYNC_INTERCOM_REQUEST'
export const SYNC_INTERCOM_SUCCESS: string = 'devices/SYNC_INTERCOM_SUCCESS'
export const SYNC_INTERCOM_ERROR: string = 'devices/SYNC_INTERCOM_ERROR'

export const SYNC_CAMERA_REQUEST: string = 'devices/SYNC_CAMERA_REQUEST'
export const SYNC_CAMERA_SUCCESS: string = 'devices/SYNC_CAMERA_SUCCESS'
export const SYNC_CAMERA_ERROR: string = 'devices/SYNC_CAMERA_ERROR'

export const SYNC_INTERCOM_CAMERA_REQUEST: string =
  'devices/SYNC_INTERCOM_CAMERA_REQUEST'
export const SYNC_INTERCOM_CAMERA_SUCCESS: string =
  'devices/SYNC_INTERCOM_CAMERA_SUCCESS'
export const SYNC_INTERCOM_CAMERA_ERROR: string =
  'devices/SYNC_INTERCOM_CAMERA_ERROR'

export const UNLINK_CAMERA_REQUEST: string = 'devices/UNLINK_CAMERA_REQUEST'
export const UNLINK_CAMERA_SUCCESS: string = 'devices/UNLINK_CAMERA_SUCCESS'
export const UNLINK_CAMERA_ERROR: string = 'devices/UNLINK_CAMERA_ERROR'
