//@flow
import * as actions from './actions'
import Api from 'utils/api'

export const getIntercomsByAddress = (address: string) => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.getIntercomsByAddressRequest())
  try {
    const intercoms = await Api.request(`/intercoms?address_uuid=${address}`)
    dispatch(actions.getIntercomsByAddressSuccess(intercoms))
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.getIntercomsByAddressError(message))
  }
}

export const getCamerasByAddress = (address: string) => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.getCamerasByAddressRequest())
  try {
    const cameras = await Api.request(
      `/cameras?address_uuid=${address}&belong_to_intercom=false`,
    )
    dispatch(actions.getCamerasByAddressSuccess(cameras))
  } catch (err) {
    console.log(err)
    const { message } = await err.json()
    dispatch(actions.getCamerasByAddressError(message))
  }
}

export const getUnlinkedIntercoms = (q, fromApi) => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.getUnlinkedIntercomsRequest())
  try {
    const unlinkedIntercoms = await Api.request(
      `/intercoms?q=${q}${fromApi ? `&from_api=true` : ''}`,
    )
    dispatch(actions.getUnlinkedIntercomsSuccess(unlinkedIntercoms))
    return Promise.resolve(unlinkedIntercoms)
  } catch (err) {
    dispatch(actions.getUnlinkedIntercomsError(err))
    return Promise.reject()
  }
}

export const getUnlinkedCameras = (serialNumber?: number) => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.getUnlinkedCamerasRequest())
  try {
    const unlinkedCameras = await Api.request(
      `/cameras${
        serialNumber ? `?serial_number=${serialNumber}` : ''
      }?linked=false`,
    )
    dispatch(actions.getUnlinkedCamerasSuccess(unlinkedCameras))
  } catch (err) {
    dispatch(actions.getUnlinkedCamerasError(err))
  }
}

type ILinkNewDeviceParams = {
  identifier: string,
  address: string,
  entry: string,
  first_flat: string,
  last_flat: string,
}

type ILinkDeviceToEntry = {
  entry_uuid: string,
  device_uuid: string,
  device_type: 'intercom' | 'camera',
}

export const linkDevice = (
  params: ILinkNewDeviceParams | ILinkDeviceToEntry,
) => async (dispatch: Function): Promise<any> => {
  dispatch(actions.linkDeviceRequest())
  try {
    const getParams = () => {
      let res = []
      //$FlowFixMe
      Object.entries(params).forEach(([key, val]) => {
        if (typeof val !== 'undefined') {
          return res.push(`${key}=${val}`)
        }
      })
      return res.join('&')
    }
    await Api.request(`/installation_points/link_device?${getParams()}`, {
      method: 'PUT',
    })

    const msg = 'Устройство было успешно привязано'
    dispatch(actions.linkDeviceSuccess(msg))
    return Promise.resolve(msg)
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.linkDeviceError(message))
    return Promise.reject(message)
  }
}

export const unlinkIntercom = (
  intercomUuid: string,
  houseUuid: string,
) => async (dispatch: Function): Promise<any> => {
  dispatch(actions.unlinkIntercomRequest())
  try {
    await Api.request(
      `/installation_points/unlink_intercom?identifier=${intercomUuid}&house_uuid=${houseUuid}`,
      {
        method: 'PUT',
      },
    )

    const msg = 'Устройство было успешно отвязано'
    dispatch(actions.unlinkIntercomSuccess(msg))
    return Promise.resolve(msg)
  } catch (err) {
    const msg = 'При отвязке устройства произошла ошибка'
    dispatch(actions.unlinkIntercomError(msg))
    return Promise.reject(msg)
  }
}

export const getCamera = (id: string) => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.getCameraRequest())
  try {
    const [camera] = await Api.request(`/cameras?intercom_id=${id}`)

    dispatch(actions.getCameraSuccess(camera))
    return Promise.resolve(camera)
  } catch (err) {
    const msg = ''
    dispatch(actions.getCameraError(msg))
    return Promise.reject(msg)
  }
}

export const syncIntercom = (uuid: string) => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.syncIntercomRequest())
  try {
    await Api.request(`/intercoms/${uuid}/sync`, { method: 'PUT' })

    dispatch(
      actions.syncIntercomSuccess('Устройство было успешно синхронизировано'),
    )
    return Promise.resolve()
  } catch (err) {
    const msg = 'При отвязке устройства произошла ошибка'
    dispatch(actions.syncIntercomError(msg))
    return Promise.reject()
  }
}

export const syncCamera = (cameraUuid: string) => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.syncCameraRequest())
  try {
    await Api.request(`/cameras/${cameraUuid}/syncs`, {
      method: 'POST',
    })

    dispatch(actions.syncCameraSuccess('Камера была успешно синхронизирована'))
    return Promise.resolve()
  } catch (err) {
    const msg = 'При синхронизации камеры произошла ошибка'
    dispatch(actions.syncCameraError(msg))
    return Promise.reject()
  }
}

export const syncIntercomCamera = (intercomUuid: string) => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.syncIntercomCameraRequest())
  try {
    await Api.request(`/intercoms/${intercomUuid}/camera/syncs`, {
      method: 'POST',
    })

    dispatch(
      actions.syncIntercomCameraSuccess('Камера была успешно синхронизирована'),
    )
    return Promise.resolve()
  } catch (err) {
    const msg = 'При синхронизации камеры произошла ошибка'
    dispatch(actions.syncIntercomCameraError(msg))
    return Promise.reject()
  }
}

export const unlinkCamera = (uuid) => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.unlinkCameraRequest())
  try {
    await Api.request(`/cameras/${uuid}/unlink`, {
      method: 'PATCH',
    })

    const msg = 'Камера было успешно отвязана'
    dispatch(actions.unlinkCameraSuccess(msg))
    return Promise.resolve(msg)
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.unlinkCameraError(message))
    return Promise.reject(message)
  }
}
