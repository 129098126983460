import React from 'react'
import { Loader, Wrapper } from 'sputnik-ui'
import { connect } from 'react-redux'
import { Key, ConfirmActionModal, AddKeyModal, FoldButton } from 'components'
import styled, { css } from 'styled-components'
import { withRouter } from 'react-router-dom'

import CardHeader from '@material-ui/core/CardHeader'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import Tooltip from '@material-ui/core/Tooltip'

import { withStyles } from '@material-ui/core/styles'

import SyncIcon from '@material-ui/icons/Sync'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import DeleteSweep from '@material-ui/icons/DeleteSweep'

import {
  syncKeys,
  deleteAllKeys,
  removeKey,
  getKeys,
} from 'store/keys/operations'

const StyledWrapper = styled(Wrapper).attrs(p => ({}))`
  background: ${p => p.theme.palette.primary[500]};
  color: ${p => p.theme.palette.primary.contrastText};
`

const ListWrapper = styled.div`
  /* transition: max-height 300ms ease-in; */
  max-height: 0rem;
  padding: 0;
  overflow-y: scroll;
  background: ${p => p.theme.palette.background.primary};
  color: ${p => p.theme.palette.text.primary};


  &:last-child {
    padding-bottom: 0;
  }
  &::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(192, 192, 192);
  }

  ${p =>
    p.isExpanded &&
    css`
      padding: 16px 0 !important;
      max-height: 26rem !important;
    `}
`

const styles = theme => ({
  heading: {
    color: theme.palette.primary.contrastText,
  },
  card: {
    // overflowY: 'scroll',
  },
  cardTitle: {
    fontSize: '1.2rem',
    fontWeight: 300,
    color: theme.palette.primary.contrastText,
  },
  cardHeader: {
    backgroundColor: 'inherit',
    color: 'inherit',
    position: 'sticky',
    zIndex: 2,
    top: 0,
    height: 80,
  },
  cardAction: {
    marginTop: 0,
    marginRight: -8,
  },
  list: {
    padding: 0,
  },
  arrowIcon: {
    transition: 'all 300ms ease-in',
  },
  arrowRotated: {
    transform: 'rotate(180deg)',
  },
})

class Keys extends React.Component {
  state = {
    deleteKeyModal: false,
    toDelete: null,
    expanded: false,
    addKeyModal: false,
    deleteAllKeysModal: false,
    page: 1,
  }

  onDeleteKeyModalClose = () =>
    this.setState({ deleteKeyModal: false, toDelete: null })

  onDeleteKey = () => {
    const { dispatch, current } = this.props

    dispatch(removeKey(current.uuid, this.state.toDelete)).then(() =>
      dispatch(getKeys(current.uuid)),
    )
    this.onDeleteKeyModalClose()
  }

  onModalOpen = key => () =>
    this.setState({
      deleteKeyModal: true,
      toDelete: key,
    })

  loadNextPage = e => {
    if (
      this.keysList.scrollTop + this.keysList.clientHeight >=
      this.keysList.scrollHeight
    ) {
      const { dispatch, current } = this.props

      this.setState({ page: this.state.page + 1 }, () =>
        dispatch(getKeys(current.uuid, this.state.page)),
      )
    }
  }

  toggleModal = modal => () =>
    this.setState({
      [modal]: !this.state[modal],
    })

  syncKeys = () => {
    this.props
      .dispatch(syncKeys(this.props.current.uuid))
      .then(this.toggleModal('syncKeys'))
  }

  deleteAllKeys = () => {
    this.props
      .dispatch(deleteAllKeys(this.props.current.uuid))
      .then(() => this.props.dispatch(getKeys(this.props.current.uuid)))
      .then(this.toggleModal('deleteAllKeysModal'))
    this.toggleModal('deleteAllKeysModal')
  }

  render() {
    const { keys, classes, isUnpaid } = this.props

    return (
      <div
        style={{
          position: 'relative',
        }}
      >
        <StyledWrapper
          style={{ padding: 0, flexDirection: 'column', overflow: 'hidden' }}
          isUnpaid={isUnpaid}
        >
          <CardHeader
            avatar={<VpnKeyIcon />}
            title={
              isUnpaid ? (
                <Typography
                  color="inherit"
                  style={{
                    fontSize: '1.2rem',
                  }}
                >
                  Управление ключами доступно только на{' '}
                  <Typography
                    color="inherit"
                    style={{
                      fontSize: '1.2rem',
                    }}
                    inline
                    variant="body2"
                    component="a"
                    href="https://partner.sputnik.systems/subscriptions"
                  >
                    Базовом тарифе
                  </Typography>
                </Typography>
              ) : (
                <Typography
                  color="inherit"
                  style={{
                    fontSize: '1.2rem',
                  }}
                >
                  {keys && keys.length < 1 ? 'Список ключей пуст' : 'Ключи'}
                </Typography>
              )
            }
            action={
              !isUnpaid && (
                <React.Fragment>
                  <Tooltip title="Удалить все ключи" placement="top">
                    <IconButton
                      onClick={this.toggleModal('deleteAllKeysModal')}
                    >
                      <DeleteSweep color="error" />
                    </IconButton>
                  </Tooltip>

                  {/* add collecting keys check */}
                  {this.props.current && (
                    <Tooltip title="Синхронизировать ключи" placement="top">
                      <IconButton
                        color="inherit"
                        onClick={this.toggleModal('syncKeys')}
                      >
                        <SyncIcon />
                      </IconButton>
                    </Tooltip>
                  )}


                  <Tooltip placement="top" title="Добавить ключ">
                    <IconButton
                      color="inherit"
                      onClick={this.toggleModal('addKeyModal')}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              )
            }
            className={classes.cardHeader}
            classes={{
              action: classes.cardAction,
            }}
          />
          <ListWrapper
            isExpanded={this.state.expanded}
            onScroll={this.loadNextPage}
            ref={n => (this.keysList = n)}
          >
            <List>
              {keys ? (
                keys.length > 0 ? (
                  keys.map(k => (
                    <Key key={k.id} onClick={this.onModalOpen(k.id)}>
                      {k.data}
                    </Key>
                  ))
                ) : (
                  <Typography align="center">
                    Список ключей для данной панели пуст.
                  </Typography>
                )
              ) : (
                <Loader msg="Загружаем список ключей..." fluid />
              )}
            </List>
          </ListWrapper>

          <ConfirmActionModal
            action="удалить ключ"
            handleClose={this.onDeleteKeyModalClose}
            handleAction={this.onDeleteKey}
            open={this.state.deleteKeyModal}
          />
        </StyledWrapper>
        {keys && keys.length > 0 && (
          <Tooltip placement="bottom" title="Отобразить список">
            <FoldButton
              onClick={() => this.setState({ expanded: !this.state.expanded })}
              disableRipple
              isExpanded={this.state.expanded}
            />
          </Tooltip>
        )}

        <ConfirmActionModal
          action="удалить все ключи"
          handleClose={this.toggleModal('deleteAllKeysModal')}
          handleAction={this.deleteAllKeys}
          open={this.state.deleteAllKeysModal}
        />
        <ConfirmActionModal
          action="синхронизировать ключи"
          handleClose={this.toggleModal('syncKeys')}
          handleAction={this.syncKeys}
          open={this.state.syncKeys}
        />
        <AddKeyModal
          handleClose={this.toggleModal('addKeyModal')}
          open={this.state.addKeyModal}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ resources: { current }, keys }) => ({
  current,
  keys: keys.list,
})

export default connect(mapStateToProps)(withStyles(styles)(withRouter(Keys)))
