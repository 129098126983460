import React from 'react'
import { Loader, Wrapper } from 'sputnik-ui'

import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import MapIcon from '@material-ui/icons/Map'

import LeafletMap from './_Map'

const styles = theme => ({
  avatar: {
    // position: 'absolute',
  },
  heading: {
    color: theme.palette.primary.contrastText,
  },
  card: {
    // boxShadow: '0 4px 12px 0.5px rgba(0,0,0,0.08)',
  },
  cardTitle: {
    fontSize: '1.2rem',
    fontWeight: 300,
    color: theme.palette.primary.contrastText,
    textAlign: 'left',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary['main'],
    color: theme.palette.primary.contrastText,
    height: 80,
  },
  cardAction: {
    marginTop: 0,
    marginRight: -8,
  },
  cardContent: {
    overflowY: 'hidden',
    height: 400,
    padding: '0 !important',
  },
  list: {
    padding: 0,
  },
  listItem: {},
  listAction: {
    marginRight: '1rem',
  },
})

const Map = ({ entry, classes, action }) => {
  return (
    <Wrapper
      style={{ padding: 0, flexDirection: 'column', overflow: 'hidden' }}
      border={false}
    >
      <CardHeader
        avatar={<MapIcon />}
        title={
          <Typography
            color="inherit"
            style={{
              fontSize: '1.2rem',
            }}
          >
            На карте
          </Typography>
        }
        action={<IconButton color="inherit" />}
        className={classes.cardHeader}
        classes={{
          action: classes.cardAction,
          avatar: classes.avatar,
        }}
      />
      <CardContent className={classes.cardContent}>
        {entry ? (
          <LeafletMap data={entry} />
        ) : (
          <Loader
            msg="Загружаем информацию о местоположении устройства..."
            fluid
          />
        )}
      </CardContent>
    </Wrapper>
  )
}

export default withStyles(styles)(Map)
