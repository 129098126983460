import * as types from './types'
import { CLEAR_RESOURCES } from '../resources/types'
import { CLEAR_STATUS } from '../ui/types'

const initialState = {
  msg: null,
  error: null,
  isUnpaid: false,
  list: [],
}

export default function keys(state = initialState, { type, payload }) {
  switch (type) {
    case types.GET_KEYS_SUCCESS:
      return {
        ...state,
        list: payload.firstPage
          ? payload.keys
          : [...state.list, ...payload.keys],
      }
    case types.GET_KEYS_ERROR:
      return { ...state, isUnpaid: payload.isUnpaid }
    case types.REMOVE_KEY_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.REMOVE_KEY_ERROR:
      return { ...state, error: payload.error }
    case types.ADD_KEY_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.ADD_KEY_ERROR:
      return { ...state, error: payload.error }
    case types.DELETE_ALL_KEYS_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.DELETE_ALL_KEYS_ERROR:
      return { ...state, error: payload.error }
    case types.SYNC_KEYS_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.SYNC_KEYS_ERROR:
      return { ...state, error: payload.error }
    case CLEAR_STATUS:
      return { ...state, error: null, msg: null }
    case CLEAR_RESOURCES:
      return initialState
    default:
      return state
  }
}
