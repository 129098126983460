//@flow
import * as React from 'react'
import styled from 'styled-components'
import { Button } from 'sputnik-ui'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { MainTemplate, Countries } from 'components'
import { getCountries } from 'store/operations'
import AddAddressModal from '../../organisms/AddAddressModal'
import SearchAddressModal from '../../organisms/SearchAddressModal'
import SearchCitizenModal from 'components/organisms/SearchCitizenModal'
import { Typography } from '@material-ui/core'
import { clearResources } from 'store/resources/actions'
import UnlinkByPhone from 'components/organisms/UnlinkByPhone'

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;

  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    flex-direction: column;
    align-items: center;
  }

  & > a {
    margin-bottom: 0.625rem;
    &:not(:first-child) {
      margin-left: 0.625rem;
    }
  }
`

function HomePage({ dispatch, history, location }) {
  React.useEffect(() => {
    dispatch(getCountries())
    return () => clearResources()
  }, [dispatch])
  return (
    <MainTemplate>
      <Typography variant="h5">Навигация</Typography>

      <Countries />

      <Typography variant="h5" style={{ marginBottom: '1.625rem' }}>
        Действия
      </Typography>
      <Wrapper>
        <Button
          color="primary"
          variant="contained"
          rounded
          component={Link}
          to="/add_address"
        >
          Добавить новый адрес
        </Button>
        <Button
          color="primary"
          variant="contained"
          rounded
          component={Link}
          to="/search_address"
        >
          Найти устройство
        </Button>
        <Button
          color="primary"
          variant="contained"
          rounded
          component={Link}
          to="/search_citizen"
        >
          Найти жителя
        </Button>
        <Button
          color="primary"
          variant="contained"
          rounded
          component={Link}
          to="/unlink_by_phone"
        >
          Отвязать по жителя по номеру
        </Button>
      </Wrapper>

      <AddAddressModal
        handleClose={history.goBack}
        open={location.pathname === '/add_address'}
      />
      <SearchAddressModal
        handleClose={history.goBack}
        open={location.pathname === '/search_address'}
      />
      <SearchCitizenModal
        handleClose={history.goBack}
        open={location.pathname === '/search_citizen'}
      />
      <UnlinkByPhone
        handleClose={history.goBack}
        open={location.pathname === '/unlink_by_phone'}
      />
    </MainTemplate>
  )
}
export default connect()(withRouter(HomePage))
