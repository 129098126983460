import * as types from './types'

export const getKeysRequest = () => ({
  type: types.GET_KEYS_REQUEST,
})
export const getKeysSuccess = (keys, firstPage) => ({
  type: types.GET_KEYS_SUCCESS,
  payload: { keys, firstPage },
})
export const getKeysError = isUnpaid => ({
  type: types.GET_KEYS_ERROR,
  payload: { isUnpaid },
})

export const addKeyRequest = () => ({
  type: types.ADD_KEY_REQUEST,
})
export const addKeySuccess = msg => ({
  type: types.ADD_KEY_SUCCESS,
  payload: { msg },
})
export const addKeyError = error => ({
  type: types.ADD_KEY_ERROR,
  payload: { error },
})

export const removeKeyRequest = () => ({
  type: types.REMOVE_KEY_REQUEST,
})
export const removeKeySuccess = msg => ({
  type: types.REMOVE_KEY_SUCCESS,
  payload: { msg },
})
export const removeKeyError = error => ({
  type: types.REMOVE_KEY_ERROR,
  payload: { error },
})

export const deleteAllKeysRequest = () => ({
  type: types.DELETE_ALL_KEYS_REQUEST,
})
export const deleteAllKeysSuccess = (msg: string) => ({
  type: types.DELETE_ALL_KEYS_SUCCESS,
  payload: {
    msg,
  },
})
export const deleteAllKeysError = (error: string) => ({
  type: types.DELETE_ALL_KEYS_ERROR,
  payload: {
    error,
  },
})

export const syncKeysRequest = () => ({
  type: types.SYNC_KEYS_REQUEST,
})
export const syncKeysSuccess = (msg: string) => ({
  type: types.SYNC_KEYS_SUCCESS,
  payload: {
    msg,
  },
})
export const syncKeysError = (error: string) => ({
  type: types.SYNC_KEYS_ERROR,
  payload: {
    error,
  },
})
