import * as types from './types'
import { CLEAR_STATUS } from '../ui/types'

const initialState = {
  // countries
  countries: null,
  // regions
  regions: null,
  // localities
  localities: null,
  // areas
  areas: null,
  // streets
  streets: null,
  // houses
  houses: null,
  // entries
  entries: null,
  // flats
  flats: null,
  // citizens
  citizens: null,
  //
  current: null,
  error: null,
  msg: null,
}

function resources(state = initialState, { type, payload }) {
  switch (type) {
    // countries
    case types.GET_COUNTRIES_SUCCESS:
      return { ...state, current: payload, error: null }
    case types.GET_COUNTRIES_ERROR:
      return { ...state, error: payload }
    case types.FETCH_COUNTRY_SUCCESS:
      return { ...state, current: payload.country, error: null }
    case types.FETCH_COUNTRY_FAILURE:
      return { ...state, error: payload.error }
    // regions
    case types.GET_REGIONS_SUCCESS:
      return { ...state, regions: payload, error: null }
    case types.GET_REGIONS_ERROR:
      return { ...state, error: payload }
    case types.FETCH_REGION_SUCCESS:
      return { ...state, current: payload.region, error: null }
    case types.FETCH_REGION_FAILURE:
      return { ...state, error: payload.error }
    // localities
    case types.GET_LOCALITIES_SUCCESS:
      return { ...state, localities: payload, error: null }
    case types.GET_LOCALITIES_ERROR:
      return { ...state, error: payload }
    case types.FETCH_LOCALITY_SUCCESS:
      return { ...state, current: payload.locality, error: null }
    case types.FETCH_LOCALITY_FAILURE:
      return { ...state, error: payload.error }
    // areas
    case types.GET_AREAS_SUCCESS:
      return { ...state, areas: payload, error: null }
    case types.GET_AREAS_ERROR:
      return { ...state, error: payload }
    case types.FETCH_AREA_SUCCESS:
      return { ...state, current: payload.area, error: null }
    case types.FETCH_AREA_FAILURE:
      return { ...state, error: payload.error }
    // streets
    case types.GET_STREETS_SUCCESS:
      return { ...state, streets: payload, error: null }
    case types.GET_STREETS_ERROR:
      return { ...state, error: payload }
    case types.FETCH_STREET_SUCCESS:
      return { ...state, current: payload.street, error: null }
    case types.FETCH_STREET_FAILURE:
      return { ...state, error: payload.error }
    // houses
    case types.GET_HOUSES_SUCCESS:
      return { ...state, houses: payload, error: null }
    case types.GET_HOUSES_ERROR:
      return { ...state, error: payload }
    case types.FETCH_HOUSE_SUCCESS:
      return { ...state, current: payload.house, error: null }
    case types.FETCH_HOUSE_FAILURE:
      return { ...state, error: payload.error }
    // entries
    case types.GET_ENTRIES_SUCCESS:
      return { ...state, entries: payload, error: null }
    case types.GET_ENTRIES_ERROR:
      return { ...state, error: payload }
    case types.FETCH_ENTRY_SUCCESS:
      return { ...state, current: payload.entry, error: null }
    case types.FETCH_ENTRY_FAILURE:
      return { ...state, error: payload.error }
    // flats
    case types.GET_FLATS_SUCCESS:
      return { ...state, flats: payload, error: null }
    case types.GET_FLATS_ERROR:
      return { ...state, error: payload }
    case types.CHANGE_FLAT_RANGE_SUCCESS:
      return { ...state, msg: payload }
    case types.CHANGE_FLAT_RANGE_ERROR:
      return { ...state, error: payload }
    case types.FETCH_FLAT_SUCCESS:
      return { ...state, current: payload.flat, error: null }
    case types.FETCH_FLAT_FAILURE:
      return { ...state, error: payload.error }
    // citizens
    case types.GET_CITIZENS_SUCCESS:
      return { ...state, citizens: payload }
    case types.GET_CITIZENS_ERROR:
      return { ...state, error: payload }
    case types.UNLINK_CITIZEN_ERROR:
      return { ...state, error: payload }
    //
    case types.CLEAR_RESOURCES:
      return initialState
    case types.CLEAR_ENTRIES:
      return { ...state, entries: null }
    case CLEAR_STATUS:
      return { ...state, error: null, msg: null }
    default:
      return state
  }
}

export default resources
