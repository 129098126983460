// https://github.com/diegohaz/arc/wiki/Atomic-Design#templates
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container } from 'sputnik-ui'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100vw - (100vw - 100%));
  padding-top: 2rem;
`

const Content = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media screen and (max-width: ${p => p.theme.sizes.tablet}) {
    justify-content: unset;
  }
`

const MainTemplate = ({ header, children, margin, subheader, ...props }) => {
  return (
    <Wrapper {...props}>
      <Content margin={margin}>{children}</Content>
    </Wrapper>
  )
}

MainTemplate.propTypes = {
  subheader: PropTypes.node,
  children: PropTypes.any.isRequired,
  margin: PropTypes.string,
}

export default MainTemplate
