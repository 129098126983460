//@flow
import * as actions from './actions'
import Api from 'utils/api'
import { showInfoMessage } from 'store/ui/actions'

// countries
export const getCountries =
  () =>
  async (dispatch: Function): Promise<any> => {
    dispatch(actions.getCountriesRequest())
    try {
      const countries = await Api.request('/countries')

      dispatch(actions.getCountriesSuccess(countries))
    } catch (err) {
      dispatch(actions.getCountriesError(err))
    }
  }

export const fetchCountry =
  (countryUuid: string) =>
  async (dispatch: Function): Promise<any> => {
    dispatch(actions.fetchCountryRequest())
    try {
      const countries = await Api.request(`/countries/${countryUuid}`)

      dispatch(actions.fetchCountrySuccess(countries))
    } catch (err) {
      dispatch(actions.fetchCountryFailure(err))
    }
  }

// regions

type IGetRegions = {
  country_uuid: string,
  q: string,
}

export const getRegions =
  ({ country_uuid, q }: IGetRegions) =>
  async (dispatch: Function): Promise<any> => {
    dispatch(actions.getRegionsRequest())
    try {
      const setParams = () => {
        let params = []

        if (country_uuid) params.push(`country_uuid=${country_uuid}`)
        if (q) params.push(`q=${q}`)

        params = params.join('&')

        return params.length > 0 ? `?${params}` : params
      }

      const regions = await Api.request(`/regions${setParams()}`)

      dispatch(actions.getRegionsSuccess(regions))
    } catch (err) {
      dispatch(actions.getRegionsError(err))
    }
  }

export const fetchRegion =
  (regionUuid: string) =>
  async (dispatch: Function): Promise<any> => {
    dispatch(actions.fetchRegionRequest())
    try {
      const region = await Api.request(`/regions/${regionUuid}`)

      dispatch(actions.fetchRegionSuccess(region))
    } catch (err) {
      dispatch(actions.fetchRegionFailure(err))
    }
  }

// localities

type IGetLocalities = {
  country_uuid: string,
  region_uuid: string,
  area_uuid: string,
  q: string,
}

export const getLocalities =
  ({ country_uuid, region_uuid, area_uuid, q }: IGetLocalities) =>
  async (dispatch: Function): Promise<any> => {
    dispatch(actions.getLocalitiesRequest())
    try {
      const setParams = () => {
        let params = []

        if (country_uuid) params.push(`country_uuid=${country_uuid}`)
        if (region_uuid) params.push(`region_uuid=${region_uuid}`)
        if (area_uuid) params.push(`area_uuid=${area_uuid}`)
        if (q) params.push(`q=${q}`)

        params = params.join('&')

        return params.length > 0 ? `?${params}` : params
      }

      const localities = await Api.request(`/localities${setParams()}`)

      dispatch(actions.getLocalitiesSuccess(localities))
    } catch (err) {
      dispatch(actions.getLocalitiesError(err))
    }
  }

export const fetchLocality =
  (localityUuid: string) =>
  async (dispatch: Function): Promise<any> => {
    dispatch(actions.fetchLocalityRequest())
    try {
      const locality = await Api.request(`/localities/${localityUuid}`)

      dispatch(actions.fetchLocalitySuccess(locality))
    } catch (err) {
      dispatch(actions.fetchLocalityFailure(err))
    }
  }

// areas

type IGet = {
  parent_uuid: string,
  q: string,
}

export const getAreas =
  ({ parent_uuid, q }: IGet) =>
  async (dispatch: Function): Promise<any> => {
    dispatch(actions.getAreasRequest())
    try {
      const setParams = () => {
        let params = []

        if (parent_uuid) params.push(`parent_uuid=${parent_uuid}`)
        if (q) params.push(`q=${q}`)

        params = params.join('&')

        return params.length > 0 ? `?${params}` : params
      }

      const areas = await Api.request(`/areas${setParams()}`)

      dispatch(actions.getAreasSuccess(areas))
    } catch (err) {
      dispatch(actions.getAreasError(err))
    }
  }

export const fetchArea =
  (areaUuid: string) =>
  async (dispatch: Function): Promise<any> => {
    dispatch(actions.fetchAreaRequest())
    try {
      const area = await Api.request(`/areas/${areaUuid}`)

      dispatch(actions.fetchAreaSuccess(area))
    } catch (err) {
      dispatch(actions.fetchAreaFailure(err))
    }
  }

// streets

export const getStreets =
  ({ parent_uuid, q }: IGet) =>
  async (dispatch: Function): Promise<any> => {
    dispatch(actions.getStreetsRequest())
    try {
      const setParams = () => {
        let params = []

        if (parent_uuid) params.push(`parent_uuid=${parent_uuid}`)
        if (q) params.push(`q=${q}`)

        params = params.join('&')

        return params.length > 0 ? `?${params}` : params
      }

      const streets = await Api.request(`/streets${setParams()}`)

      dispatch(actions.getStreetsSuccess(streets))
    } catch (err) {
      dispatch(actions.getStreetsError(err))
    }
  }

export const fetchStreet =
  (streetUuid: string) =>
  async (dispatch: Function): Promise<any> => {
    dispatch(actions.fetchStreetRequest())
    try {
      const area = await Api.request(`/streets/${streetUuid}`)

      dispatch(actions.fetchStreetSuccess(area))
    } catch (err) {
      dispatch(actions.fetchStreetFailure(err))
    }
  }

// houses

export const getHouses =
  ({ parent_uuid, q }: IGet) =>
  async (dispatch: Function): Promise<any> => {
    dispatch(actions.getHousesRequest())
    try {
      const setParams = () => {
        let params = []

        if (parent_uuid) params.push(`parent_uuid=${parent_uuid}`)
        if (q) params.push(`q=${q}`)

        params = params.join('&')

        return params.length > 0 ? `?${params}` : params
      }

      const houses = await Api.request(`/houses${setParams()}`)

      dispatch(actions.getHousesSuccess(houses))
    } catch (err) {
      dispatch(actions.getHousesError(err))
    }
  }

export const fetchHouse =
  (houseUuid: string) =>
  async (dispatch: Function): Promise<any> => {
    dispatch(actions.fetchHouseRequest())
    try {
      const house = await Api.request(`/houses/${houseUuid}`)

      dispatch(actions.fetchHouseSuccess(house))
    } catch (err) {
      dispatch(actions.fetchHouseFailure(err))
    }
  }

export const getEntries =
  ({ parent_uuid, q }: IGet) =>
  async (dispatch: Function): Promise<any> => {
    dispatch(actions.getEntriesRequest())
    try {
      const setParams = () => {
        let params = []

        if (parent_uuid) params.push(`parent_uuid=${parent_uuid}`)
        if (q) params.push(`q=${q}`)

        params = params.join('&')

        return params.length > 0 ? `?${params}` : params
      }

      const entries = await Api.request(`/entries${setParams()}`)

      dispatch(actions.getEntriesSuccess(entries))
    } catch (err) {
      dispatch(actions.getEntriesError(err))
    }
  }

export const fetchEntry =
  (uuid: string) =>
  async (dispatch: Function): Promise<any> => {
    dispatch(actions.fetchEntryRequest())
    try {
      const entry = await Api.request(`/entries/${uuid}`)

      dispatch(actions.fetchEntrySuccess(entry))
    } catch (err) {
      dispatch(actions.fetchEntryFailure(err))
    }
  }

type IGetFlats = {
  flat: string,
  q?: string,
}

export const getFlats =
  ({ parent_uuid, q }: IGet) =>
  async (dispatch: Function): Promise<any> => {
    dispatch(actions.getFlatsRequest())
    try {
      const setParams = () => {
        let params = []

        if (parent_uuid) params.push(`parent_uuid=${parent_uuid}`)
        if (q) params.push(`q=${q}`)

        params = params.join('&')

        return params.length > 0 ? `?${params}` : params
      }

      const flats = await Api.request(`/flats${setParams()}`)

      dispatch(actions.getFlatsSuccess(flats))
    } catch (err) {
      dispatch(actions.getFlatsError(err))
    }
  }

type IChangeFlat = {
  entry: string,
  firstFlat: number,
  lastFlat: number,
}

export const changeFlatRange =
  ({ entry, firstFlat, lastFlat, custom_numbering }: IChangeFlat) =>
  async (dispatch: Function): Promise<any> => {
    dispatch(actions.changeFlatRangeRequest())
    try {
      await Api.request(
        `/entries/${entry}/update_range?first_flat=${firstFlat}&last_flat=${lastFlat}&custom_numbering=${custom_numbering}`,
        { method: 'PUT' }
      )
      dispatch(
        actions.changeFlatRangeSuccess('Диапазон квартир был успешно обновлен')
      )
    } catch (err) {
      dispatch(
        actions.changeFlatRangeError(
          'При обновлении диапазона квартир произошла ошибка'
        )
      )
    }
  }

export const fetchFlat =
  (flatUuid: string) =>
  async (dispatch: Function): Promise<any> => {
    dispatch(actions.fetchFlatRequest())
    try {
      const entry = await Api.request(`/flats/${flatUuid}`)

      dispatch(actions.fetchFlatSuccess(entry))
    } catch (err) {
      dispatch(actions.fetchFlatFailure(err))
    }
  }

export const getCitizens =
  ({ flat, q }: IGetFlats) =>
  async (dispatch: Function): Promise<any> => {
    dispatch(actions.getCitizensRequest())
    try {
      const setParams = () => {
        let params = []
        if (q) params.push(`q=${q}`)
        params = params.join('&')
        return params.length > 0 ? `&${params}` : params
      }

      const flats = await Api.request(
        `/citizens/?flat_uuid=${flat}${setParams()}`
      )

      dispatch(actions.getCitizensSuccess(flats))
    } catch (err) {
      dispatch(actions.getCitizensError(err))
    }
  }

export const unlinkCitizen =
  (id: string) =>
  async (dispatch: Function): Promise<any> => {
    dispatch(actions.unlinkCitizenRequest())
    try {
      await Api.request(`/citizens/${id}`, { method: 'DELETE' })

      dispatch(actions.unlinkCitizenSuccess())
      return Promise.resolve()
    } catch (err) {
      dispatch(
        actions.unlinkCitizenError('При отвязке жителя произошла ошибка')
      )
      return Promise.reject(err)
    }
  }

export const removeFlat = (uuid) => async (dispatch) => {
  dispatch(actions.removeFlatRequest())
  try {
    await Api.request(`/public/flats/${uuid}`, { method: 'DELETE' })

    dispatch(actions.removeFlatSuccess())
    dispatch(showInfoMessage('Квартира успешно удалена'))
    return Promise.resolve()
  } catch (err) {
    dispatch(actions.removeFlatError('При удалении произошла ошибка'))
    dispatch(showInfoMessage('При удалении произошла ошибка'))
    return Promise.reject(err)
  }
}
