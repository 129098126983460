//@flow
import * as React from 'react'
import { connect } from 'react-redux'
import { SingleChildTemplate, Citizens, ConfirmActionModal } from 'components'
import { withRouter } from 'react-router-dom'

import { unlinkCitizen } from 'store/operations'
import { clearResources } from 'store/actions'
import { fetchFlat } from 'store/resources/operations'

function FlatPage({
  dispatch,
  match: {
    params: { flat },
  },
}) {
  const [userToUnlink, setUserToUnlink] = React.useState(null)
  React.useEffect(() => {
    if (flat) {
      dispatch(fetchFlat(flat))
    }
    return () => dispatch(clearResources())
  }, [dispatch, flat])

  return (
    <SingleChildTemplate>
      <Citizens confirmAction={user => () => setUserToUnlink(user)} />
      <ConfirmActionModal
        action="отвязать жителя"
        open={!!userToUnlink}
        handleClose={() => setUserToUnlink(null)}
        handleAction={() =>
          dispatch(unlinkCitizen(userToUnlink)).then(() => {
            setUserToUnlink(null)
            dispatch(fetchFlat(flat))
          })
        }
      />
    </SingleChildTemplate>
  )
}

export default connect()(withRouter(FlatPage))
