import React from 'react'
import { Button } from 'sputnik-ui'
import { connect } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Zoom from '@material-ui/core/Zoom'
import Progress from '@material-ui/core/CircularProgress'

import SyncIcon from '@material-ui/icons/Sync'

import { syncCamera } from 'store/operations'
import { Typography } from '@material-ui/core'
import { syncIntercomCamera } from 'store/devices/operations'

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

class CameraModal extends React.Component {
  onSync = () => {
    console.log(this.props)
    this.props
      .dispatch(
        this.props.camera?.device_uuid || this.props.device?.uuid
          ? syncIntercomCamera(
              this.props.camera?.device_uuid || this.props.device.uuid
            )
          : syncCamera(this.props.camera.uuid)
      )
      .then(this.props.handleClose)
  }

  render() {
    const { camera } = this.props
    const streamUrl = camera && camera.stream_url.match(/[^/]+$/)[0]
    return (
      <Dialog
        open={this.props.open}
        TransitionComponent={Transition}
        onClose={this.props.handleClose}
        PaperProps={{ style: styles.dialog }}
      >
        <DialogTitle style={{ paddingBottom: 5, marginBottom: 20 }}>
          Камера {streamUrl}
        </DialogTitle>
        <DialogContent
          style={{
            overflow: 'visible',
          }}
        >
          {camera ? (
            camera.embed ? (
              <div style={styles.responsiveContainer}>
                <iframe
                  title="camera-stream"
                  src={camera.embed}
                  frameBorder="0"
                  style={styles.responsiveElement}
                  autoPlay={false}
                />
              </div>
            ) : (
              <Typography color="error" align="center">
                Поток с камеры недоступен
              </Typography>
            )
          ) : (
            <Progress />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.handleClose}
            secondary
            rounded
            size="small"
          >
            закрыть
          </Button>
          <Button
            primary
            variant="contained"
            rounded
            onClick={this.onSync}
            size="small"
          >
            <SyncIcon style={styles.icon} /> Синхронизировать камеру
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const styles = {
  icon: {
    marginRight: 8,
    width: '1rem',
    height: '1rem',
  },
  dialog: {
    overflow: 'visible',
    width: '80%',
    maxWidth: 800,
  },
  responsiveContainer: {
    height: 0,
    paddingTop: '56.25%',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 5,
    boxShadow: '0px 0px 30px rgba(0,0,0,0.1)',
  },
  responsiveElement: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgb(250, 251, 250)',
  },
}

export default connect()(CameraModal)
