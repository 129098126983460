import React from 'react'
import { connect } from 'react-redux'
import { Loader } from 'sputnik-ui'
import { withRouter } from 'react-router-dom'
import { Card, withSearch } from 'components'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import MapIcon from '@material-ui/icons/Map'

import { selectArea } from 'store/actions'
import { getAreas } from 'store/operations'

const searchAreas = (props, query) => {
  const { selectedCountry, selectedRegion, dispatch } = props

  const country_uuid = (selectedCountry && selectedCountry.uuid) || null
  const region_uuid = (selectedRegion && selectedRegion.uuid) || null
  const q = query || null
  const parent_uuid = (country_uuid && region_uuid) || null

  dispatch(
    getAreas({
      parent_uuid,
      q,
    }),
  )
}

const Areas = ({ areas, err, dispatch, history, match, ...props }) => {
  if (!areas) {
    return <Loader msg="Загружаем список районов..." err={err} fluid />
  }

  return (
    <Card title="Районы" titleIcon={<MapIcon />} {...props}>
      {areas.map(a => (
        <ListItem
          key={a.uuid}
          button
          onClick={() => {
            dispatch(selectArea(a))
            history.push(`/a/${a.uuid}`)
          }}
        >
          <ListItemIcon>
            <MapIcon />
          </ListItemIcon>
          <ListItemText primary={a.name} />
          <ListItemSecondaryAction style={{ paddingRight: '1rem' }}>
            <ArrowForwardIcon />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </Card>
  )
}

const mapStateToProps = ({ resources }) => ({
  areas: resources.current && resources.current.areas,
  err: resources.err,
})

const RegionsWithSearch = withSearch(searchAreas)(Areas)
export default connect(mapStateToProps)(withRouter(RegionsWithSearch))
