import React from 'react'
import { Tooltip, IconButton } from '@material-ui/core'
import { connect } from 'react-redux'
import config from 'config'

const EntryQRCode = ({ entry, dispatch, style, props }) => {
  return entry ? (
    <Tooltip title="Cкачать QR-код">
      <IconButton>
        {/* <object type="image/svg+xml" data="kiwi.svg" class="logo">
        Kiwi Logo
      </object> */}
        <a
          style={{ height: 24 }}
          href={`${config.baseUri}/entries/${entry.uuid}/generate_qr`}
        >
          <svg
            shapeRendering="crispEdges"
            height="24"
            width="24"
            viewBox="0 0 21 21"
            id="houseQR"
            style={{ cursor: 'pointer', ...style }}
            {...props}
          >
            <path fill="#ffffff" d="M0,0 h21v21H0z" />
            <path
              fill="rgba(0, 0, 0, 0.87)"
              d="M0 0h7v1H0zM8 0h1v1H8zM10 0h3v1H10zM14,0 h7v1H14zM0 1h1v1H0zM6 1h1v1H6zM10 1h2v1H10zM14 1h1v1H14zM20,1 h1v1H20zM0 2h1v1H0zM2 2h3v1H2zM6 2h1v1H6zM8 2h2v1H8zM11 2h1v1H11zM14 2h1v1H14zM16 2h3v1H16zM20,2 h1v1H20zM0 3h1v1H0zM2 3h3v1H2zM6 3h1v1H6zM8 3h2v1H8zM12 3h1v1H12zM14 3h1v1H14zM16 3h3v1H16zM20,3 h1v1H20zM0 4h1v1H0zM2 4h3v1H2zM6 4h1v1H6zM8 4h1v1H8zM11 4h1v1H11zM14 4h1v1H14zM16 4h3v1H16zM20,4 h1v1H20zM0 5h1v1H0zM6 5h1v1H6zM9 5h4v1H9zM14 5h1v1H14zM20,5 h1v1H20zM0 6h7v1H0zM8 6h1v1H8zM10 6h1v1H10zM12 6h1v1H12zM14,6 h7v1H14zM11 7h2v1H11zM0 8h4v1H0zM6 8h1v1H6zM8 8h6v1H8zM16 8h3v1H16zM20,8 h1v1H20zM0 9h1v1H0zM2 9h3v1H2zM7 9h2v1H7zM10 9h5v1H10zM17 9h2v1H17zM20,9 h1v1H20zM0 10h1v1H0zM2 10h2v1H2zM6 10h1v1H6zM8 10h1v1H8zM10 10h2v1H10zM19,10 h2v1H19zM4 11h2v1H4zM7 11h1v1H7zM9 11h3v1H9zM14 11h4v1H14zM20,11 h1v1H20zM0 12h4v1H0zM5 12h2v1H5zM12 12h1v1H12zM15 12h1v1H15zM20,12 h1v1H20zM8 13h4v1H8zM14 13h1v1H14zM17 13h2v1H17zM0 14h7v1H0zM9 14h1v1H9zM11 14h2v1H11zM15 14h1v1H15zM17 14h2v1H17zM0 15h1v1H0zM6 15h1v1H6zM15 15h2v1H15zM18,15 h3v1H18zM0 16h1v1H0zM2 16h3v1H2zM6 16h1v1H6zM9 16h2v1H9zM12 16h6v1H12zM20,16 h1v1H20zM0 17h1v1H0zM2 17h3v1H2zM6 17h1v1H6zM8 17h2v1H8zM11 17h1v1H11zM14 17h6v1H14zM0 18h1v1H0zM2 18h3v1H2zM6 18h1v1H6zM8 18h1v1H8zM10 18h1v1H10zM12 18h1v1H12zM14 18h2v1H14zM0 19h1v1H0zM6 19h1v1H6zM8 19h1v1H8zM13 19h1v1H13zM15 19h1v1H15zM18 19h1v1H18zM20,19 h1v1H20zM0 20h7v1H0zM8 20h2v1H8zM13 20h1v1H13zM16 20h1v1H16z"
            />
          </svg>
        </a>
      </IconButton>
    </Tooltip>
  ) : null
}

const mapStateToProps = ({ resources: { current } }) => ({
  entry: current,
})

export default connect(mapStateToProps)(EntryQRCode)
