import React from 'react'
import { connect } from 'react-redux'
import { Loader } from 'sputnik-ui'
import { withRouter } from 'react-router-dom'
import { Card, withSearch } from 'components'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import TerrainIcon from '@material-ui/icons/Terrain'

import { getRegions } from 'store/operations'
import { selectRegion } from 'store/actions'

const searchRegions = (props, query) => {
  const { selectedCountry, dispatch } = props

  const country_uuid = (selectedCountry && selectedCountry.uuid) || null
  const q = query || null

  dispatch(
    getRegions({
      country_uuid,
      q,
    }),
  )
}

const Regions = ({
  regions,
  err,
  classes,
  dispatch,
  history,
  match,
  ...props
}) => {
  if (!regions) {
    return <Loader msg="Загружаем список регионов..." err={err} fluid />
  }

  return (
    <Card title="Регионы" titleIcon={<TerrainIcon />} {...props}>
      {regions.map(r => (
        <ListItem
          key={r.uuid}
          button
          onClick={() => {
            dispatch(selectRegion(r))
            history.push(`/r/${r.uuid}`)
          }}
        >
          <ListItemIcon>
            <TerrainIcon />
          </ListItemIcon>
          <ListItemText primary={r.name} />
          <ListItemSecondaryAction style={{ marginRight: '1rem' }}>
            <ArrowForwardIcon />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </Card>
  )
}

const mapStateToProps = ({ resources }) => ({
  regions: resources.current && resources.current.regions,
  err: resources.err,
})

const searchComp = withSearch(searchRegions)(Regions)

export default connect(mapStateToProps)(withRouter(searchComp))
