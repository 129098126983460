//@flow
import * as React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from 'components/App'
import registerServiceWorker from './registerServiceWorker'
import store from './store/store'
import { Provider } from 'react-redux'
import {
  theme,
  Intercom,
  i18n,
  Loader,
  ThemeProvider,
  Metrika,
} from 'sputnik-ui'
import { I18nextProvider } from 'react-i18next'

import 'rc-slider/assets/index.css'

const Root = (): React.Node => (
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <React.Suspense fallback={<Loader />}>
            <App />
            <Intercom />
            <Metrika />
          </React.Suspense>
        </I18nextProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
)

const node = document.getElementById('root')

if (node === null) {
  throw new Error('no node element')
}

ReactDOM.render(<Root />, node)
registerServiceWorker()
