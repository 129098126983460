import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Loader } from 'sputnik-ui'
import { withRouter } from 'react-router-dom'

import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { selectCountry } from 'store/actions'
import config from 'config'

const styles = theme => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
})

const Wrapper = styled.div`
  width: 100%;
`

const Flags = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  /* each flag */
  & > div {
    width: 180px;
    cursor: pointer;
    margin: 2rem;
    transition: box-shadow 0.2s ease-in;

    &:hover {
      & p {
        color: rgba(0,0,0,0.75);
      }
      & > div {
        box-shadow: 0px 2px 30px rgba(0,0,0,0.3);
      }
    },
  }
`

const Countries = ({ countries, classes, dispatch, history, ...props }) => {
  return (
    <Wrapper {...props}>
      {countries && Array.isArray(countries) ? (
        <>
          <Flags>
            {countries.map(c => (
              <div key={c.uuid}>
                <Card
                  onClick={() => {
                    dispatch(selectCountry(c))
                    history.push(`/c/${c.uuid}`)
                  }}
                  style={{
                    transition: 'box-shadow 0.2s ease-in',
                  }}
                >
                  <CardMedia
                    className={classes.media}
                    image={c.flag_url}
                    // image={`${config.redirectUri}${c.flag_thumb_url}`}
                    title={c.name}
                  />
                </Card>
                <Typography
                  align="center"
                  style={{
                    marginTop: '1.125rem',
                    textTransform: 'uppercase',
                    transition: 'color 0.2s ease-in',
                  }}
                  variant="body1"
                  color="primary"
                >
                  {c.name}
                </Typography>
              </div>
            ))}
          </Flags>
        </>
      ) : (
        <Loader
          fullWidth
          msg="Загружаем список стран..."
          style={{ height: 'auto' }}
        />
      )}
    </Wrapper>
  )
}

const mapStateToProps = ({ resources }) => ({
  countries: resources.current,
})

export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(Countries)),
)
