import * as actions from './actions'
import Api from 'utils/api'

export const searchCitizen = (phoneNumber: string) => async dispatch => {
  dispatch(actions.searchRequest())
  try {
    const citizens = await Api.request(`/citizens?phone=${phoneNumber}`)
    // -------------------- begin devil code ------------------------
    //                             ,-.
    //        ___,---.__          /'|`\          __,---,___
    //     ,-'    \`    `-.____,-'  |  `-.____,-'    //    `-.
    //  ,'        |           ~'\     /`~           |        `.
    // /      ___//              `. ,'          ,  , \___      \
    // |    ,-'   `-.__   _         |        ,    __,-'   `-.    |
    // |   /          /\_  `   .    |    ,      _/\          \   |
    // \  |           \ \`-.___ \   |   / ___,-'/ /           |  /
    //  \  \           | `._   `\\  |  //'   _,' |           /  /
    //   `-.\         /'  _ `---'' , . ``---' _  `\         /,-'
    //      ``       /     \    ,='/ \`=.    /     \       ''
    //              |__   /|\_,--.,-.--,--._/|\   __|
    //              /  `./  \\`\ |  |  | /,//' \,'  \
    //             /   /     ||--+--|--+-/-|     \   \
    //            |   |     /'\_\_\ | /_/_/`\     |   |
    //             \   \__, \_     `~'     _/ .__/   /
    //              `-._,-'   `-._______,-'   `-._,-'
    // --------------------------------------------------------------
    if (citizens.length < 1) {
      throw 'Не удалось найти жителя'
    }
    const [citizen] = citizens
    dispatch(actions.searchSuccess())
    return Promise.resolve(citizen)
  } catch (error) {
    let message
    try {
      message = await error.json()
    } catch {
      message = error
    }
    dispatch(actions.searchFailure(message))
    return Promise.reject(error)
  }
}

export const unlinkByPhone = (phoneNumber: string) => async dispatch => {
  dispatch(actions.unlinkByPhoneRequest())
  try {
    const citizen = await Api.request(`/citizens/${phoneNumber}/deactivate`, {
      method: 'PUT',
    })

    dispatch(actions.unlinkByPhoneSuccess('Житель был успешно отвязан'))
    return Promise.resolve(citizen)
  } catch (error) {
    let message
    try {
      message = await error.json()
    } catch {
      message = error
    }
    dispatch(actions.unlinkByPhoneFailure(message))
    return Promise.reject(error)
  }
}
