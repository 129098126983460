//@flow
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, useInput } from 'sputnik-ui'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Zoom from '@material-ui/core/Zoom'
import TextField from '@material-ui/core/TextField'

import { unlinkByPhone } from 'store/citizens/operations'

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

function UnlinkByPhone({ open, handleClose, history }) {
  const { value: phoneNumber, bind: bindPhoneNumber } = useInput('')
  const dispatch = useDispatch()
  const citizensError = useSelector(({ citizens }) => !!citizens.error)

  const handleSubmit = React.useCallback(
    e => {
      e.preventDefault()

      // change first letter of phone number
      let phone = phoneNumber
      if (phoneNumber.match(/^\+/)) {
        phone = phone.slice(1)
      } else if (phoneNumber.match(/^8/)) {
        phone = '7' + phone.slice(1)
      }

      dispatch(unlinkByPhone(phone)).then(handleClose)
    },
    [dispatch, phoneNumber, handleClose],
  )

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      PaperProps={{ style: styles.content }}
    >
      <DialogTitle>Отвязать жителя</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent style={{ paddingTop: 0 }}>
          <TextField
            {...bindPhoneNumber}
            fullWidth
            label="Номер телефона"
            placeholder="88005553535"
            margin="dense"
            autoFocus
            error={citizensError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} secondary rounded tabIndex={-1}>
            отмена
          </Button>
          <Button
            primary
            variant="contained"
            rounded
            disabled={!phoneNumber || phoneNumber.length < 11}
            type="submit"
          >
            отвязать
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

const styles = {
  content: { width: '45%' },
  submitBtn: { marginLeft: 'auto', marginTop: 16 },
}

export default withRouter(UnlinkByPhone)
