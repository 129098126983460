//@flow
import * as types from './types'

type State = {
  +collection: ?(Object[]),
  +error: ?string,
  +unlinkedIntercoms: ?(Object[]),
  +cameras: ?(Object[]),
  +unlinkedCameras: ?(Object[]),
}

type Action = {
  type: string,
  payload: any,
}

const initialState: State = {
  collection: null,
  unlinkedIntercoms: null,
  cameras: null,
  unlinkedCameras: null,
  error: null,
  msg: null,
  unlinkingCamera: false,
}

export default function devices(
  state: State = initialState,
  { type, payload }: Action,
) {
  switch (type) {
    case types.GET_INTERCOMS_BY_ADDRESS_SUCCESS:
      return { ...state, collection: payload.intercoms, error: null }
    case types.GET_INTERCOMS_BY_ADDRESS_ERROR:
      return { ...state, error: payload }
    case types.GET_UNLINKED_INTERCOMS_SUCCESS:
      return { ...state, unlinkedIntercoms: payload.intercoms, error: null }
    case types.GET_UNLINKED_INTERCOMS_ERROR:
      return { ...state, error: payload }
    case types.GET_CAMERAS_BY_ADDRESS_SUCCESS:
      return { ...state, cameras: payload.cameras, error: null }
    case types.GET_CAMERAS_BY_ADDRESS_ERROR:
      return { ...state, error: payload }
    case types.GET_UNLINKED_CAMERAS_SUCCESS:
      return { ...state, unlinkedCameras: payload.cameras, error: null }
    case types.GET_UNLINKED_CAMERAS_ERROR:
      return { ...state, error: payload }
    case types.LINK_DEVICE_SUCCESS:
      return { ...state, msg: payload, error: null }
    case types.LINK_DEVICE_ERROR:
      return { ...state, msg: null, error: payload }
    case types.UNLINK_INTERCOM_SUCCESS:
      return { ...state, msg: payload, error: null }
    case types.UNLINK_INTERCOM_ERROR:
      return { ...state, msg: null, error: payload }
    case types.GET_CAMERA_SUCCESS:
      return { ...state, camera: payload, error: null }
    case types.GET_CAMERA_ERROR:
      return { ...state, error: payload }
    case types.SYNC_INTERCOM_SUCCESS:
      return { ...state, msg: payload }
    case types.SYNC_INTERCOM_ERROR:
      return { ...state, error: payload }
    case types.SYNC_CAMERA_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.SYNC_CAMERA_ERROR:
      return { ...state, error: payload.error }
    case types.SYNC_INTERCOM_CAMERA_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.SYNC_INTERCOM_CAMERA_ERROR:
      return { ...state, error: payload.error }
    case types.UNLINK_CAMERA_REQUEST:
      return { ...state, unlinkingCamera: true }
    case types.UNLINK_CAMERA_SUCCESS:
      return { ...state, msg: payload.msg, unlinkingCamera: false }
    case types.UNLINK_CAMERA_ERROR:
      return { ...state, error: payload.error, unlinkingCamera: false }
    case 'resources/CLEAR':
      return initialState
    case 'ui/CLEAR_STATUS':
      return { ...state, msg: null, error: null }
    default:
      return state
  }
}
