import React from 'react'
import { Button, Wrapper, Container } from 'sputnik-ui'
import { connect } from 'react-redux'
import {
  ConfirmActionModal,
  AttachNewCameraModal,
  AttachNewIntercomModal,
  ChangeFlatRangeModal,
} from 'components'
import { withStyles } from '@material-ui/core/styles'
import styled, { css } from 'styled-components'
import { IconButton, Tooltip } from '@material-ui/core'
import { AttachFile, LocationCity, Videocam } from '@material-ui/icons'

import {
  syncInstallationPoint,
  closeCollectingKeys,
  openCollectingKeys,
  prolongCollectingKeys,
  fetchEntry,
  invalidateQr,
} from 'store/operations'
import EntryQRCode from 'components/molecules/EntryQRCode'

const styles = (theme) => ({
  wrapper: {
    background: '#fafafa',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '.325rem 1.625rem',
  },
  action: {
    fontWeight: '400',
    '&:hover': {
      transform: 'none !important',
    },
    '& svg': {
      marginRight: 15,
    },
  },
})

const StyledWrapper = styled(Wrapper)`
  background: ${(p) => p.theme.palette.other.pink};
  justify-content: center;
  align-items: center;
  padding: 0.325rem 0;
  border-radius: 0;
  flex-wrap: wrap;

  ${(p) =>
    p.pink &&
    css`
      background: ${(p) => p.theme.palette.white['500']};
      border-bottom: ${(p) => p.theme.palette.grayscale[0]} 1px solid;
    `}
`

class InstallationPointActions extends React.Component {
  state = {
    closeCollectingKeysModal: false,
    syncModal: false,
    openCollectingKeysModal: false,
    invalidateQrModal: false,
    attachNewCameraModal: false,
    changeFlatRangeModal: false,
    attachNewIntercomModal: false,
  }

  toggleModal = (modal) => () => this.setState({ [modal]: !this.state[modal] })

  syncInstallationPoint = () => {
    const { entry, dispatch } = this.props
    dispatch(syncInstallationPoint(entry.uuid)).then(
      this.toggleModal('syncModal')
    )
  }

//   openCollectingKeysModal = () => {
//     const { entry, dispatch } = this.props
//     dispatch(openCollectingKeys(entry.uuid)).then(() => {
//       dispatch(fetchEntry(entry.uuid))
//       this.toggleModal('openCollectingKeysModal')()
//     })
//   }

//   closeCollectingKeys = () => {
//     const { entry, dispatch } = this.props
//     dispatch(closeCollectingKeys(entry.uuid)).then(() => {
//       dispatch(fetchEntry(entry.uuid))
//       this.toggleModal('closeCollectingKeysModal')()
//     })
//   }

//   prolongCollectingKeys = () => {
//     const { entry, dispatch } = this.props
//     dispatch(prolongCollectingKeys(entry.uuid))
//   }

  invalidateQr = () => {
    const { dispatch, entry } = this.props
    console.log('kek')
    dispatch(invalidateQr(entry.uuid)).then(
      this.toggleModal('invalidateQrModal')
    )
  }

  render() {
    const { classes, entry } = this.props
    return (
      <>
        {' '}
        <StyledWrapper shadow={false} border={false}>
          <Container style={{ flexWrap: 'inherit' }}>
            {entry && (
              <React.Fragment>
                <Button
                  variant="text"
                  color="primary"
                  rounded
                  className={classes.action}
                  onClick={this.toggleModal('invalidateQrModal')}
                >
                  сбросить qr-код
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  rounded
                  className={classes.action}
                  onClick={this.toggleModal('syncModal')}
                >
                  Синхронизировать
                </Button>
                {/* {entry.opened ? (
                  <React.Fragment>
                    <Button
                      variant="text"
                      color="primary"
                      rounded
                      className={classes.action}
                      onClick={this.toggleModal('closeCollectingKeysModal')}
                    >
                      Закрыть автосбор
                    </Button>
                    <Button
                      variant="text"
                      color="primary"
                      rounded
                      className={classes.action}
                      onClick={this.prolongCollectingKeys}
                    >
                      Продлить автосбор
                    </Button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Button
                      variant="text"
                      color="primary"
                      rounded
                      className={classes.action}
                      onClick={this.toggleModal('openCollectingKeysModal')}
                    >
                      открыть автосбор
                    </Button>
                  </React.Fragment>
                )} */}
              </React.Fragment>
            )}
            <ConfirmActionModal
              description="Это действие приведет к недействительности всех ранее сгенерированных QR-кодов"
              action="сбросить QR-код"
              handleClose={this.toggleModal('invalidateQrModal')}
              handleAction={this.invalidateQr}
              open={this.state.invalidateQrModal}
            />
            {/* <ConfirmActionModal
              open={this.state.openCollectingKeysModal}
              action="открыть автосбор"
              description="Это приведет к потере всех ключей."
              handleClose={this.toggleModal('openCollectingKeysModal')}
              handleAction={this.openCollectingKeysModal}
            />
            <ConfirmActionModal
              open={this.state.closeCollectingKeysModal}
              action="закрыть автосбор"
              description="Повторное включение автосбора приведет к потере всех ключей"
              handleClose={this.toggleModal('closeCollectingKeysModal')}
              handleAction={this.closeCollectingKeys}
            /> */}
            <ConfirmActionModal
              open={this.state.syncModal}
              action="синхронизировать точку установки"
              handleClose={this.toggleModal('syncModal')}
              handleAction={this.syncInstallationPoint}
            />
          </Container>
        </StyledWrapper>
        <StyledWrapper shadow={false} border={false} pink>
          <Container>
            <EntryQRCode />

            <Tooltip title="Изменить диапазон квартир" placement="bottom">
              <IconButton
                color="inherit"
                onClick={this.toggleModal('changeFlatRangeModal')}
                disableRipple
              >
                <LocationCity />
              </IconButton>
            </Tooltip>
            <Tooltip title="Прикрепить другое устройство" placement="bottom">
              <IconButton
                color="inherit"
                onClick={this.toggleModal('attachNewIntercomModal')}
                disableRipple
              >
                <AttachFile />
              </IconButton>
            </Tooltip>
            <Tooltip title="Прикрепить новую камеру" placement="bottom">
              <IconButton
                color="inherit"
                onClick={this.toggleModal('attachNewCameraModal')}
                disableRipple
              >
                <Videocam />
              </IconButton>
            </Tooltip>
          </Container>

          <AttachNewCameraModal
            open={this.state.attachNewCameraModal}
            handleClose={this.toggleModal('attachNewCameraModal')}
          />
          <ChangeFlatRangeModal
            open={this.state.changeFlatRangeModal}
            handleClose={this.toggleModal('changeFlatRangeModal')}
          />
          <AttachNewIntercomModal
            open={this.state.attachNewIntercomModal}
            handleClose={this.toggleModal('attachNewIntercomModal')}
          />
        </StyledWrapper>
      </>
    )
  }
}

const mapStateToProps = ({
  selected: { entry },
  installations: { msg, error },
  resources: { current },
}) => ({
  entry: current,
  msg,
  error,
})

export default connect(mapStateToProps)(
  withStyles(styles)(InstallationPointActions)
)
