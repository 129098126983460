//@flow
import * as actions from './actions'
import Api from 'utils/api'

export const syncInstallationPoint = (addressUuid: string) => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.syncInstallationPointRequest())
  try {
    await Api.request(`/installation_points/${addressUuid}/sync`, {
      method: 'PUT',
    })
    const msg = 'Точка установки была успешно синхронизирована'
    dispatch(actions.syncInstallationPointSuccess(msg))
    return Promise.resolve()
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.syncInstallationPointError(message))
    return Promise.reject()
  }
}

// export const closeCollectingKeys = (addressUuid: string) => async (
//   dispatch: Function,
// ): Promise<any> => {
//   dispatch(actions.closeCollectingKeysRequest())
//   try {
//     await Api.request(
//       `/installation_points/${addressUuid}/collecting_keys/close`,
//       { method: 'PATCH' },
//     )
//     dispatch(actions.closeCollectingKeysSuccess('Автосбор был отключен'))
//     return Promise.resolve()
//   } catch (err) {
//     const { message } = await err.json()
//     dispatch(actions.closeCollectingKeysError(message))
//     return Promise.reject()
//   }
// }

// export const openCollectingKeys = (addressUuid: string) => async (
//   dispatch: Function,
// ): Promise<any> => {
//   dispatch(actions.openCollectingKeysRequest())
//   try {
//     await Api.request(
//       `/installation_points/${addressUuid}/collecting_keys/open`,
//       { method: 'PATCH' },
//     )
//     dispatch(actions.openCollectingKeysSuccess('Автосбор был включен'))
//     return Promise.resolve()
//   } catch (err) {
//     const { message } = await err.json()
//     dispatch(actions.openCollectingKeysError(message))
//     return Promise.reject()
//   }
// }

// export const prolongCollectingKeys = (addressUuid: string) => async (
//   dispatch: Function,
// ): Promise<any> => {
//   dispatch(actions.prolongCollectingKeysRequest())
//   try {
//     await Api.request(
//       `/installation_points/${addressUuid}/collecting_keys/prolong`,
//       { method: 'PATCH' },
//     )
//     dispatch(
//       actions.prolongCollectingKeysSuccess('Автосбор был успешно продлен'),
//     )
//     return Promise.resolve()
//   } catch (err) {
//     const { message } = await err.json()
//     dispatch(actions.prolongCollectingKeysError(message))
//     return Promise.reject()
//   }
// }

export const searchInstallationPoint = (identifier: string) => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.searchInstallationPointRequest())
  try {
    const {
      address_uuid: addressUuid,
      address_type: addressType,
    } = await Api.request(`/devices/${identifier}`)

    if (!addressUuid || !addressType) {
      dispatch(
        actions.searchInstallationPointError('Устройство не установлено'),
      )
      return Promise.reject('Устройство не установлено')
    }

    dispatch(actions.searchInstallationPointSuccess())
    return Promise.resolve({ addressUuid, addressType })
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.searchInstallationPointError(message))
    return Promise.reject(message)
  }
}

export const generateQr = (entryUuid: string) => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.generateQrRequest())
  try {
    await Api.request(`/entries/${entryUuid}/generate_qr`)
    dispatch(actions.generateQrSuccess())
    return Promise.resolve()
  } catch (err) {
    const message = 'lel'
    // const { message } = await err.json()
    dispatch(actions.generateQrError(err))
    return Promise.reject(message)
  }
}

export const invalidateQr = (entryUuid: string) => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.invalidateQrRequest())
  try {
    await Api.request(`/entries/${entryUuid}/invalidate_qr`, {
      method: 'PATCH',
    })
    dispatch(actions.invalidateQrSuccess('Код был успешно сброшен'))
    return Promise.resolve()
  } catch (err) {
    console.log(err)
    const { message } = await err.json()
    dispatch(actions.invalidateQrError(message))
    return Promise.reject(message)
  }
}
