// https://github.com/diegohaz/arc/wiki/Atomic-Design#templates
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100vw - (100vw - 100%));
`

const Content = styled.section`
  height: 100%;
  margin-top: 7.125rem;
  display: flex;
`

const AppTemplate = ({ header, children, subheader, ...props }) => {
  return (
    <Wrapper {...props}>
      {header}
      <Content>{children}</Content>
    </Wrapper>
  )
}

AppTemplate.propTypes = {
  header: PropTypes.node.isRequired,
  subheader: PropTypes.node,
  children: PropTypes.any.isRequired,
}

export default AppTemplate
