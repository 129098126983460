import * as types from './types'

const initialState = {
  isLoggedIn: false,
  error: null
}

export default function auth(state = initialState, { type, payload }) {
  switch (type) {
    case types.SIGN_IN:
      return { ...state, isLoggedIn: true, error: null, user: payload }
    case types.SIGN_IN_ERROR:
      return { ...state, error: payload }
    case types.LOG_OUT_SUCCESS:
      return { ...state, isLoggedIn: false, error: null, user: null }
    default:
      return state
  }
}
