import React from 'react'
import styled from 'styled-components'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Input from '@material-ui/core/Input'

import { Wrapper } from 'sputnik-ui'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import { Box } from '@material-ui/core'

const CardWrapper = styled(Wrapper)`
  padding: 0;
  padding-bottom: 1rem;
  overflow: hidden;
  flex-direction: column;
  background: ${(p) => p.theme.palette.background.primary};
`

const Header = styled(CardHeader)`
  && {
    background-color: ${(p) =>
      p.whiteHeader
        ? p.theme.palette.white[500]
        : p.theme.palette.primary[500]};
    color: ${(p) =>
      p.whiteHeader
        ? p.theme.palette.white.contrastText
        : p.theme.palette.primary.contrastText};
    height: 80px;
    position: sticky;
    z-index: 2;
    top: 0;
  }
`

const Card = ({
  children,
  titleIcon,
  title,
  actionIcon,
  classes,
  action,
  toggleSearch,
  isSearching,
  whiteHeader,
  onSearch,
  right,
  rightClick,
}) => (
  <CardWrapper>
    {isSearching ? (
      <Header
        action={
          <Input
            fullWidth
            type="search"
            onChange={onSearch}
            onBlur={toggleSearch}
            autoFocus
          />
        }
        classes={{
          action: classes.searchAction,
        }}
        whiteHeader={whiteHeader}
      />
    ) : (
      <Header
        avatar={titleIcon}
        title={
          <Box display="flex" justifyContent="space-between">
            <Typography
              color="inherit"
              style={{
                fontSize: '1.2rem',
              }}
            >
              {title}
            </Typography>
            <Typography
              onClick={rightClick}
              color="inherit"
              style={{
                fontSize: '1.2rem',
                cursor: 'pointer'
              }}
            >
              {right}
            </Typography>
          </Box>
        }
        // action={
        //   <IconButton onClick={toggleSearch} color="inherit">
        //     {actionIcon || (
        //       <Tooltip title="Поиск" placement="bottom">
        //         <SearchIcon />
        //       </Tooltip>
        //     )}
        //   </IconButton>
        // }
        whiteHeader={whiteHeader}
        classes={{
          action: classes.cardAction,
          avatar: classes.avatar,
        }}
      />
    )}

    <CardContent className={classes.cardContent}>
      <List component="nav" className={classes.list} dense>
        {children}
      </List>
    </CardContent>
  </CardWrapper>
)

export default withStyles(styles)(Card)
