//@flow

export const SYNC_INSTALLATION_POINT_REQUEST: string =
  'installations/SYNC_INSTALLATION_POINT_REQUEST'
export const SYNC_INSTALLATION_POINT_SUCCESS: string =
  'installations/SYNC_INSTALLATION_POINT_SUCCESS'
export const SYNC_INSTALLATION_POINT_ERROR: string =
  'installations/SYNC_INSTALLATION_POINT_ERROR'

export const CLOSE_COLLECTING_KEYS_REQUEST: string =
  'installations/CLOSE_COLLECTING_KEYS_REQUEST'
export const CLOSE_COLLECTING_KEYS_SUCCESS: string =
  'installations/CLOSE_COLLECTING_KEYS_SUCCESS'
export const CLOSE_COLLECTING_KEYS_ERROR: string =
  'installations/CLOSE_COLLECTING_KEYS_ERROR'

export const OPEN_COLLECTING_KEYS_REQUEST: string =
  'installations/OPEN_COLLECTING_KEYS_REQUEST'
export const OPEN_COLLECTING_KEYS_SUCCESS: string =
  'installations/OPEN_COLLECTING_KEYS_SUCCESS'
export const OPEN_COLLECTING_KEYS_ERROR: string =
  'installations/OPEN_COLLECTING_KEYS_ERROR'

export const PROLONG_COLLECTING_KEYS_REQUEST: string =
  'installations/PROLONG_COLLECTING_KEYS_REQUEST'
export const PROLONG_COLLECTING_KEYS_SUCCESS: string =
  'installations/PROLONG_COLLECTING_KEYS_SUCCESS'
export const PROLONG_COLLECTING_KEYS_ERROR: string =
  'installations/PROLONG_COLLECTING_KEYS_ERROR'

export const SEARCH_INSTALLATION_POINT_REQUEST: string =
  'devices/SEARCH_INSTALLATION_POINT_REQUEST'
export const SEARCH_INSTALLATION_POINT_SUCCESS: string =
  'devices/SEARCH_INSTALLATION_POINT_SUCCESS'
export const SEARCH_INSTALLATION_POINT_ERROR: string =
  'devices/SEARCH_INSTALLATION_POINT_ERROR'

export const GENERATE_QR_REQUEST: string = 'devices/GENERATE_QR_REQUEST'
export const GENERATE_QR_SUCCESS: string = 'devices/GENERATE_QR_SUCCESS'
export const GENERATE_QR_ERROR: string = 'devices/GENERATE_QR_ERROR'

export const INVALIDATE_QR_REQUEST: string = 'devices/INVALIDATE_QR_REQUEST'
export const INVALIDATE_QR_SUCCESS: string = 'devices/INVALIDATE_QR_SUCCESS'
export const INVALIDATE_QR_ERROR: string = 'devices/INVALIDATE_QR_ERROR'
