import React from 'react'
import { connect } from 'react-redux'
import { Loader } from 'sputnik-ui'
import { withRouter } from 'react-router-dom'
import { Card, withSearch } from 'components'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import LocationCityIcon from '@material-ui/icons/LocationCity'

import { getLocalities } from 'store/operations'
import { selectLocality } from 'store/actions'

const searchLocalities = (props, query) => {
  const { selectedCountry, selectedRegion, selectedArea, dispatch } = props

  const country_uuid = (selectedCountry && selectedCountry.uuid) || null
  const region_uuid = (selectedRegion && selectedRegion.uuid) || null
  const area_uuid = (selectedArea && selectedArea.uuid) || null
  const q = query || null

  dispatch(
    getLocalities({
      area_uuid,
      country_uuid,
      region_uuid,
      q,
    }),
  )
}

const Localities = ({
  localities,
  err,
  dispatch,
  history,
  match,
  ...props
}) => {
  if (!localities) {
    return <Loader msg="Загружаем список городов..." err={err} fluid />
  }

  return (
    <Card title="Населенные пункты" titleIcon={<LocationCityIcon />} {...props}>
      {localities.map(l => (
        <ListItem
          key={l.uuid}
          button
          onClick={() => {
            dispatch(selectLocality(l))
            history.push(`/l/${l.uuid}`)
          }}
        >
          <ListItemIcon>
            <LocationCityIcon />
          </ListItemIcon>
          <ListItemText primary={l.full_name} />
          <ListItemSecondaryAction style={{ marginRight: '1rem' }}>
            <ArrowForwardIcon />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </Card>
  )
}

const mapStateToProps = ({ resources }) => ({
  localities: resources.current && resources.current.localities,
  err: resources.err,
})

let toExp = withSearch(searchLocalities)(Localities)
toExp = withRouter(toExp)

export default connect(mapStateToProps)(toExp)
