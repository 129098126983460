// https://github.com/diegohaz/arc/wiki/Atomic-Design#templates
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Section } from 'components'
import { Container } from 'sputnik-ui'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 1px;
  width: calc(100vw - (100vw - 100%));
`

const Subheader = styled.div`
  /* padding: ${p => p.theme.sizes.scale[1]} 0; */
  /* background: ${p => p.theme.palette.other.pink}; */
`

const Content = styled(Section)`
  display: flex;
  padding-top: 2rem;
  padding-bottom: 0;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0;

  & > * {
    margin-bottom: 1.625rem;
  }

  & > *:first-child {
    flex: 1;
    margin-right: 1.625rem;

    @media screen and (max-width: 800px) {
      flex: auto;
      width: 100%;
      margin-right: 0;
    }
  }

  & > *:nth-child(2) {
    flex: 1;
    align-self: flex-start;

    & > * {
      margin-bottom: 1.625rem;
    }
  }
`

const EntryPageTemplate = ({
  header,
  subheader,
  children,
  margin,
  ...props
}) => {
  return (
    <Wrapper {...props} s>
      <Subheader>{subheader}</Subheader>
      <Container>
        <Content>{children}</Content>
      </Container>
    </Wrapper>
  )
}

EntryPageTemplate.propTypes = {
  subheader: PropTypes.node,
  children: PropTypes.any.isRequired,
  margin: PropTypes.string,
}

export default EntryPageTemplate
